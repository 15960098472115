export default {
    "de": {
        "activate": "Aktivieren",
        "actualize": "Aktualisieren",
        "add_client_dialog_component": {
            "add_client": "Einen Klienten hinzuf\u00fcgen",
            "added_client": "Client hinzugef\u00fcgt",
            "client_reference": "Kundenreferenz",
            "client_space_name": "Name Raum Bedeutung",
            "company_name": "Name Unternehmen",
            "siren": "SIRENE"
        },
        "asset_domicile": {
            "address_find": "Adresse gefunden",
            "bad_marker": "Der gesetzte Punkt entspricht nicht einer ziemlich genauen Adresse",
            "complete_address_equipment": "Wie lautet die vollst\u00e4ndige Adresse Ihrer neuen Ausr\u00fcstung",
            "domicile": "Domizile",
            "domicile_name": "Name Ihres Wohnortes",
            "domicile_required": "Erforderlicher Wohnsitz",
            "error_browser_localisation": "Ihr Browser unterst\u00fctzt keine Geolokalisierung.",
            "error_geocode": "Fehler mit dem google-Geocode aufgetreten",
            "error_get_user_client_domiciles": "Beim Abrufen von Domizilen aufgetretener Fehler",
            "error_localisation_precision": "Es ist nicht m\u00f6glich, Sie richtig zu lokalisieren.",
            "error_message_domicile": "Adresse nicht genau genug",
            "inform_domicile_address": "Geben Sie Ihre Wohnadresse ein",
            "locate_me": "Mich geolokalisieren",
            "no_domicile": "Kein Wohnsitz",
            "place_on_map": "Einen Punkt auf einer Karte platzieren",
            "refuse_localisation": "Sie haben die Geolokalisierung abgelehnt.",
            "use_existing_address": "Eine bestehende Adresse verwenden",
            "use_new_address": "Eine neue Adresse verwenden"
        },
        "asset_model_form_wrapper_component": {
            "content": "Inhalt",
            "select_container_type": "Einen Beh\u00e4ltertyp ausw\u00e4hlen"
        },
        "asset_specification": {
            "code_asset": "Asset Code (optional)",
            "description": "Beschreibung",
            "equipment_information": "Informationen zur Ausstattung",
            "equipment_specifications": "Welche Eigenschaften hat Ihre Ausr\u00fcstung",
            "error_get_liquids": "Es ist nicht m\u00f6glich, Fl\u00fcssigkeiten zu sammeln.",
            "error_get_localisations": "Lokalisierungen k\u00f6nnen nicht abgerufen werden.",
            "honey_type": "Art des Honigs",
            "honey_type_required": "Art des ben\u00f6tigten Honigs",
            "intermediate_screw_pitch": "2 - Mittlerer Schraubengang",
            "large_screw_pitch": "3 - Gro\u00dfes Gewinde",
            "liquid_type": "Art der Fl\u00fcssigkeit",
            "liquid_type_required": "Art der ben\u00f6tigten Fl\u00fcssigkeit",
            "localisation": "Lokalisierung",
            "localisation_required": "Erforderliche Lokalisierung",
            "monitoring_consumption": "\u00dcberwachung des Verbrauchs",
            "monitoring_filling": "\u00dcberwachung der Bef\u00fcllung",
            "monitoring_type": "Art der Nachbereitung",
            "monitoring_type_required": "Art der erforderlichen Nachbereitung",
            "name_required": "Erforderlicher Name",
            "name_your_equipment": "Benennen Sie Ihre Ausr\u00fcstung, um sie leichter zu finden",
            "no_honey_type": "Keine zugeh\u00f6rigen Honigsorten",
            "no_liquid_type": "Keine zugeh\u00f6rigen Fl\u00fcssigkeitstypen",
            "no_screw_pitch": "Kein Schraubengewinde",
            "offset": "Offset (cm)",
            "offset_required": "Erforderlicher Offset",
            "screw_pitch_number": "Nummer Gewindesteigung",
            "small_screw_pitch": "1 - Kleines Gewinde"
        },
        "back": "Zur\u00fcck",
        "device_install": "Installation Sensor",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Kunde",
            "customer_link_to_equipment": "Client mit der Ausr\u00fcstung verkn\u00fcpfen",
            "name_equipment": "Name Ausr\u00fcstung",
            "ref_equipment": "Ref Ausr\u00fcstung"
        },
        "email": "Email",
        "error": "Interner Serverfehler",
        "error_display_option": "Thema nicht abgerufen.",
        "fill_all_fields": "Bitte f\u00fcllen Sie alle Felder aus",
        "generic_form_component": {
            "required_fields": "Erforderliche Felder"
        },
        "generic_type": {
            "equipment_type_required": "Art der ben\u00f6tigten Ausr\u00fcstung",
            "field_required": "Erforderliches Feld",
            "problem_equipment_model": "Es ist nicht m\u00f6glich, die Vorlagen f\u00fcr diese Ausr\u00fcstung abzurufen.",
            "taring_bottom": "Niedriges Tarieren",
            "taring_top": "Hohes Tarieren",
            "wrong_dateformat": "Falsches Datumsformat"
        },
        "global": {
            "activate": "Aktivieren",
            "add": "Hinzuf\u00fcgen",
            "cancel": "Abbrechen",
            "confirm": "Best\u00e4tigen"
        },
        "installation_result": {
            "add_device_image": "Ein Foto hinzuf\u00fcgen",
            "add_image_succeed": "Foto hinzugef\u00fcgt",
            "bad": "Schlecht",
            "camera_not_supported": "Nicht unterst\u00fctzte Kamera",
            "device_activation": "Jetzt m\u00fcssen Sie Ihren Sensor {device_number} nur noch manuell aktivieren. Starten Sie manuell eine Messung, um zu \u00fcberpr\u00fcfen, ob der Sensor richtig installiert ist.",
            "email_inscription_sent": "Eine E-Mail zur Anmeldung wurde gesendet",
            "email_inscription_sent_at": "Eine E-Mail wurde an die angegebene Adresse gesendet",
            "error_add_image": "Fehler beim Hinzuf\u00fcgen des Bildes",
            "error_email_inscription_sent": "Problem beim Versenden der Anmelde-E-Mail",
            "finalize_inscription": "Um die Anmeldung abzuschlie\u00dfen und die Zugangsdaten zu erhalten, klicken Sie in die E-Mail, die Ihnen zugeschickt wurde.",
            "good": "Gut",
            "height_liquid": "H\u00f6he der Fl\u00fcssigkeit",
            "installation_success": "Die Installation verlief reibungslos.",
            "medium": "Mittel",
            "network_state": "Netzwerkstatus",
            "new_installation": "Eine neue Installation durchf\u00fchren",
            "no_data": "Keine Daten",
            "no_device_emission": "Keine Sendung vom Sensor",
            "no_measure": "Keine Messungen hochgezogen",
            "realized_measure": "Durchgef\u00fchrte Ma\u00dfnahme :",
            "reminder_to_check_emails": "An die in Schritt 1 ausgef\u00fcllte E-Mail-Adresse wurde eine Aktivierungs-E-Mail gesendet, um auf die Daten des Sensors zuzugreifen.",
            "very_good": "Sehr gut",
            "waiting_measure": "Warten auf eine Ma\u00dfnahme...",
            "weight": "Gewicht"
        },
        "installation_result_balance": {
            "activate_device": "Um die Funktionst\u00fcchtigkeit Ihrer Waage zu \u00fcberpr\u00fcfen und sicherzustellen, dass Ihr Bienenstand vom Netzwerk :",
            "activate_device_later": "Ich werde meine Waage sp\u00e4ter aktivieren",
            "device_activation": "Jetzt m\u00fcssen Sie Ihre {device_number} Waage nur noch manuell aktivieren. Starten Sie manuell eine Messung, um zu \u00fcberpr\u00fcfen, ob die Waage richtig installiert ist.",
            "error_get_first_emission": "Es ist nicht m\u00f6glich, eine Sendung von der Waage abzurufen",
            "see_device_detail": "Sehen Sie sich die Details der Waage an",
            "step_1": "\u00d6ffnen Sie Ihre Box",
            "step_2": "Legen Sie die Batterie in das Batteriefach ein",
            "step_3": "Warten Sie, bis ein doppelter Piepton ert\u00f6nt (wenn kein Piepton ert\u00f6nt, warten Sie bitte 30 Sekunden, bevor Sie den Vorgang wiederholen).",
            "step_4": "Schlie\u00dfen Sie die Box wieder",
            "step_5": "Klicken Sie auf Aktivieren, um die ersten Daten von Ihrer Waage zu sammeln",
            "success_get_first_emission": "Die Waage hat einen Datensatz erfasst! Sie k\u00f6nnen die Qualit\u00e4t der Daten in den Details der Waage \u00fcberpr\u00fcfen."
        },
        "installation_result_generic": {
            "activate_device": "Um zu \u00fcberpr\u00fcfen, ob die Messwerte richtig funktionieren, schalten Sie den Sensor bitte in den \"Installationsmodus\". F\u00fchren Sie dazu den Magneten um den Sensor herum, damit die gr\u00fcne LED erscheint.",
            "activate_device_later": "Ich werde meinen Sensor sp\u00e4ter aktivieren",
            "device_activation": "Jetzt m\u00fcssen Sie Ihren Sensor {device_number} nur noch manuell aktivieren. Starten Sie manuell eine Messung, um zu \u00fcberpr\u00fcfen, ob der Sensor richtig installiert ist.",
            "error_get_first_emission": "Eine Sendung vom Sensor kann nicht abgerufen werden",
            "see_device_detail": "Details zum Sensor ansehen",
            "success_get_first_emission": "Der Sensor hat einen Datensatz erfasst! Sie k\u00f6nnen die Qualit\u00e4t der Daten in den Details des Sensors \u00fcberpr\u00fcfen."
        },
        "modules": {
            "GNC_16SNSR": "Automat 16 K\u00fcvetten",
            "GNC_8SNSR": "8-Beh\u00e4lter-Automat",
            "GNC_BMTR": "Z\u00e4hler (B-Meter)",
            "GNC_BSTRM": "W\u00e4rmer\u00fcckgewinnungsanlagen",
            "GNC_BSTRM_3": "W\u00e4rmer\u00fcckgewinnungsanlagen drei Eing\u00e4nge",
            "GNC_CHMRC": "Wiederaufbereitungsanlagen",
            "GNC_CNT": "Z\u00e4hler",
            "GNC_DLVL": "Detergent Canister",
            "GNC_EMPT": "Leeres generisches Modul",
            "GNC_GAS": "Gastanks",
            "GNC_GPS": "GPS-Tracking",
            "GNC_H2": "Wasserstoffbeh\u00e4lter",
            "GNC_HIVE": "Bienenst\u00f6cke",
            "GNC_NETWORK": "Netzwerkausstattungen",
            "GNC_NOREA": "Fl\u00fcssigkeitstanks Norea",
            "GNC_PRESSURE": "Tankdruck",
            "GNC_SCLN": "Verd\u00fcnnungsanlagen",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Tanks (CNG)"
        },
        "need_help": "Ich brauche Hilfe",
        "next": "Weiter zu",
        "no_network": "Keine Internetverbindung",
        "ok": "Ok",
        "perfect": "Perfekt!",
        "selection_on_map": {
            "click_on_map": "Einen Punkt auf einer Karte platzieren",
            "no_address": "Sie k\u00f6nnen Ihre Adresse nicht finden?"
        },
        "silo_type": {
            "brand_silo": "Marke des Silos",
            "brand_silo_required": "Erforderliche Marke",
            "cylindrical_silo_conical_bottom": "Zylindrisches Silo mit konischem Boden",
            "cylindrical_tower_bottom": "Zylindrischer Turm mit flachem Boden",
            "diameter_part_right": "Durchmesser rechter Teil (in cm)",
            "diameter_required": "Erforderlicher Durchmesser",
            "error_get_silo_brands": "Silomarken k\u00f6nnen nicht abgerufen werden.",
            "error_get_silo_models": "Es ist nicht m\u00f6glich, die Modelle dieser Silomarke abzurufen.",
            "height_required": "Erforderliche H\u00f6he",
            "height_total": "Gesamth\u00f6he (in cm)",
            "height_volume_silo": "Wie hoch ist Ihr Silo und wie gro\u00df ist sein Volumen?",
            "know_brand_model": "Kenne ich das Modell und die Marke meines Silos?",
            "length_cone": "H\u00f6he Kegel (in cm)",
            "length_cone_required": "Erforderliche Kegelh\u00f6he",
            "length_required": "Erforderliche L\u00e4nge",
            "length_right_part": "L\u00e4nge rechter Teil (in cm)",
            "length_width_minimum_cone": "L\u00e4nge/Diam min Kegel (in cm)",
            "model_silo": "Modell des Silos",
            "model_silo_required": "Erforderliches Modell",
            "rectangular_silo_pyramidal_bottom": "Rechteckiges Silo mit pyramidenf\u00f6rmigem Boden",
            "rectangular_tower_flat_bottom": "Rechteckiger Turm mit flachem Boden",
            "silo_type_required": "Typ des ben\u00f6tigten Silos",
            "value_required": "Erforderlicher Wert",
            "volume": "Volumen (m3)",
            "volume_required": "Erforderliches Volumen",
            "width_min_cone": "Mindestbreite Kegel (in cm)",
            "width_min_cone_required": "Erforderliche Mindestbreite",
            "width_required": "Erforderliche Breite",
            "width_right_part": "Breite rechter Teil (in cm)"
        },
        "step_1": {
            "client_find_not_complete": "Sie haben bereits versucht, mit dieser E-Mail-Adresse ein Kundenkonto einzurichten. Denken Sie daran, die Einrichtung des Kontos abzuschlie\u00dfen, indem Sie auf den Link klicken, den Sie per E-Mail erhalten haben.",
            "client_find_with_mail": "Kunde mit dieser E-Mail-Adresse gefunden.",
            "client_not_find_with_mail": "Klient/in mit dieser E-Mail-Adresse nicht gefunden.",
            "email_already_taken": "E-Mail bereits verwendet.",
            "email_invitation_inscription_sent": "Eine E-Mail mit einer Einladung zur Anmeldung wird verschickt, sobald die Installation abgeschlossen ist.",
            "email_not_valid": "Ung\u00fcltiges E-Mail-Format",
            "email_required": "Erforderliche E-Mail",
            "error_during_association": "Fehler, der beim Zuordnen des Sensors aufgetreten ist",
            "for_a_client": "F\u00fcr einen Klienten",
            "professional_account": "F\u00fcr das Unternehmen",
            "send_registration_mail": "Senden Sie eine Anmelde-E-Mail",
            "title": "Kontakt",
            "welcome_to_installation": "Willkommen bei der Installation eines Online-Sensors",
            "wish_associate_device_to": "Ich m\u00f6chte den Sensor verkn\u00fcpfen"
        },
        "step_2": {
            "camera_error": "Nicht unterst\u00fctzte Kamera",
            "device_cant_be_install": "Der Sensor kann nicht installiert werden.",
            "device_not_found": "Ger\u00e4t nicht gefunden",
            "device_number": "Nummer des Sensors",
            "device_series_number": "Seriennummer",
            "device_series_number_not_found": "Seriennummer nicht gefunden.",
            "device_series_number_required": "Erforderliche Seriennummer",
            "error_get_device": "Fehler, der beim Abrufen des Sensors aufgetreten ist",
            "inform_device_number": "F\u00fcllen Sie die Sensornummer aus",
            "message_associate_professional": "*Ihr Sensor ist mit dem Unternehmen verkn\u00fcpft, es gibt keinen pers\u00f6nlichen Bereich (Sens). Wenn Sie \u00fcber einen pers\u00f6nlichen Bereich auf Ihren Sensor zugreifen m\u00f6chten, geben Sie bitte im vorherigen Schritt Ihre E-Mail-Adresse ein.",
            "not_access_device_number": "Ich habe keinen Zugriff auf die Nummer des Sensors",
            "number_required": "Erforderliche Nummer",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Heizkessel",
            "chaudiere": "Heizkessel",
            "choose_equipment_type": "W\u00e4hlen Sie die Art der Ausstattung :",
            "cuve": "Tank",
            "device_associate_to": "Die Ausr\u00fcstung ist richtig mit dem Gesch\u00e4ftskonto verkn\u00fcpft",
            "equipment_type": "Art der Ausr\u00fcstung",
            "equipment_type_required": "Art der ben\u00f6tigten Ausr\u00fcstung",
            "error_get_asset_types": "Fehler beim Abrufen von Ger\u00e4tetypen aufgetreten",
            "ground_water": "Grundwasserspiegel",
            "hive": "R\u00fbche",
            "no_equipment_type": "Keine von der Art der Ausr\u00fcstung",
            "ouvrage": "Werk",
            "reel": "Aufwickler",
            "silo": "Silo",
            "title": "Art der Ausr\u00fcstung"
        },
        "step_4": {
            "appartement": "wohnung",
            "boiler": "kessel",
            "chaudiere": "kessel",
            "cuve": "tank",
            "equipment": "ausstattung",
            "error_domicile": "Domizil falsch eingestellt",
            "error_during_installation": "Fehler bei der Installation",
            "ground_water": "grundwasserspiegel",
            "machining_machine": "bearbeitungsmaschine",
            "no_installation_for_this_type": "Installation nicht f\u00fcr diesen Ger\u00e4tetyp implementiert",
            "ouvrage": "werk",
            "reel": "Aufwickler",
            "ruche": "Bienenstock",
            "silo": "silo",
            "title": "Konfiguration"
        },
        "tank_type": {
            "coated_cylindrical_format": "Zylindrisches Format gestrichen",
            "error_get_tank_brands": "Tankmarkierungen k\u00f6nnen nicht abgerufen werden",
            "error_get_tank_models": "Es ist nicht m\u00f6glich, die Modelle dieser Tankmarke abzurufen.",
            "height": "H\u00f6he (in cm)",
            "height_required": "H\u00f6he ben\u00f6tigt",
            "height_volume_tank": "Wie hoch ist Ihr Tank und wie gro\u00df ist sein Volumen?",
            "ibc_format": "IBC-Format",
            "is_mobile": "Ist radmobil?",
            "know_brand_model": "Kenne ich das Modell und die Marke meines Tanks?",
            "length_interior": "L\u00e4nge INNEN (in cm)",
            "length_interior_required": "Erforderliche L\u00e4nge",
            "rectangular_format": "Rechteckiges Format",
            "spherical_format": "Kugelf\u00f6rmiges Format",
            "tank_brand": "Marke des Beh\u00e4lters",
            "tank_brand_required": "Erforderliche Marke",
            "tank_model": "Modell des Beh\u00e4lters",
            "tank_model_required": "Erforderliches Modell",
            "tank_type_required": "Typ des ben\u00f6tigten Beh\u00e4lters",
            "vertical_cylindrical_format": "Zylindrisches Hochformat",
            "volume": "Volumen (Liter)",
            "volume_required": "Erforderliches Volumen",
            "width_interior": "Breite INNEN (in cm)",
            "width_interior_required": "Erforderliche Breite"
        },
        "validate": "Best\u00e4tigen"
    },
    "en": {
        "activate": "Activate",
        "actualize": "Refresh",
        "add_client_dialog_component": {
            "add_client": "Add a customer",
            "added_client": "Added customer",
            "client_reference": "Customer reference",
            "client_space_name": "Name space sense",
            "company_name": "Company name",
            "siren": "SIREN"
        },
        "asset_domicile": {
            "address_find": "Address found",
            "bad_marker": "The point placed does not correspond to a precise enough address",
            "complete_address_equipment": "What is the full address of your new equipment",
            "domicile": "Homes",
            "domicile_name": "Name of your home",
            "domicile_required": "Required residence",
            "error_browser_localisation": "Your browser does not support geolocation.",
            "error_geocode": "Error encountered with the google geocode",
            "error_get_user_client_domiciles": "Error encountered during the recovery of homes",
            "error_localisation_precision": "Impossible to locate you correctly.",
            "error_message_domicile": "Address not precise enough",
            "inform_domicile_address": "Enter your home address",
            "locate_me": "Geolocate me",
            "no_domicile": "No home",
            "place_on_map": "Placing a point on a map",
            "refuse_localisation": "You have refused the geolocation.",
            "use_existing_address": "Use an existing address",
            "use_new_address": "Use a new address"
        },
        "asset_model_form_wrapper_component": {
            "content": "Contents",
            "select_container_type": "Select a container type"
        },
        "asset_specification": {
            "code_asset": "Asset code (optional)",
            "description": "Description",
            "equipment_information": "Equipment information",
            "equipment_specifications": "What are the characteristics of your equipment",
            "error_get_liquids": "Impossible to recover liquids.",
            "error_get_localisations": "Unable to retrieve locations.",
            "honey_type": "Type of honey",
            "honey_type_required": "Type of honey required",
            "intermediate_screw_pitch": "2 - Intermediate thread",
            "large_screw_pitch": "3 - Large screw thread",
            "liquid_type": "Type of liquid",
            "liquid_type_required": "Type of liquid required",
            "localisation": "Location",
            "localisation_required": "Location required",
            "monitoring_consumption": "Consumption monitoring",
            "monitoring_filling": "Filling monitoring",
            "monitoring_type": "Type of monitoring",
            "monitoring_type_required": "Type of follow-up required",
            "name_required": "Name required",
            "name_your_equipment": "Name your equipment to find it more easily",
            "no_honey_type": "No honey type associated",
            "no_liquid_type": "No associated liquid type",
            "no_screw_pitch": "No screw threads",
            "offset": "Offset (cm)",
            "offset_required": "Offset required",
            "screw_pitch_number": "Thread number",
            "small_screw_pitch": "1 - Small thread"
        },
        "back": "Back",
        "device_install": "Sensor installation",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Customer",
            "customer_link_to_equipment": "Customer link to equipment",
            "name_equipment": "Equipment name",
            "ref_equipment": "Ref equipment"
        },
        "email": "Email",
        "error": "Internal server error",
        "error_display_option": "Theme not recovered.",
        "fill_all_fields": "Please fill in all fields",
        "generic_form_component": {
            "required_fields": "Required fields"
        },
        "generic_type": {
            "equipment_type_required": "Type of equipment required",
            "field_required": "Required field",
            "problem_equipment_model": "Impossible to recover the models of this equipment.",
            "taring_bottom": "Low tare",
            "taring_top": "High tare",
            "wrong_dateformat": "Wrong date format"
        },
        "global": {
            "activate": "Activate",
            "add": "Add",
            "cancel": "Cancel",
            "confirm": "Confirm"
        },
        "installation_result": {
            "add_device_image": "Add a photo",
            "add_image_succeed": "Photo added",
            "bad": "Bad",
            "camera_not_supported": "Camera not supported",
            "device_activation": "All that remains is to activate your {device_number} sensor manually. Launch a measurement manually to check that the sensor is well installed.",
            "email_inscription_sent": "A registration email has been sent",
            "email_inscription_sent_at": "An email was sent to the address indicated",
            "error_add_image": "Error while adding the image",
            "error_email_inscription_sent": "Problem encountered when sending the registration email",
            "finalize_inscription": "To finalize the registration and obtain the access codes, click in the email that was sent.",
            "good": "Good",
            "height_liquid": "Height of the liquid",
            "installation_success": "The installation went well.",
            "medium": "Meduim",
            "network_state": "Network status",
            "new_installation": "Perform a new installation",
            "no_data": "No data",
            "no_device_emission": "No emission from the sensor",
            "no_measure": "No measurements reported",
            "realized_measure": "Measurement performed:",
            "reminder_to_check_emails": "An activation email has been sent to the email address provided in step 1 to access the sensor data.",
            "very_good": "Very good",
            "waiting_measure": "Waiting for a measure...",
            "weight": "Weight"
        },
        "installation_result_balance": {
            "activate_device": "In order to verify the proper functioning of your scale and ensure that your apiary is covered by the network :",
            "activate_device_later": "I'll activate my scale later",
            "device_activation": "All that remains is to activate your {device_number} scale manually. Run a measurement manually to check that the scale is properly installed.",
            "error_get_first_emission": "Unable to retrieve a program from the scale",
            "see_device_detail": "See the details of the balance",
            "step_1": "Open your case",
            "step_2": "Insert the battery into the battery compartment",
            "step_3": "Wait for a double beep (if no beep, please wait 30 seconds before trying again)",
            "step_4": "Close the box",
            "step_5": "Click on activate to collect the first data from your scale",
            "success_get_first_emission": "The scale has returned a data! You can check the quality of the data in the details of the scale."
        },
        "installation_result_generic": {
            "activate_device": "To check the correct functioning of the readings please put the sensor in \"installation\" mode. To do this, pass the magnet around the sensor to make the green LED appear.",
            "activate_device_later": "I will activate my sensor later",
            "device_activation": "You just have to activate your {device_number} sensor manually. Launch a measurement manually to check that the sensor is well installed.",
            "error_get_first_emission": "Unable to retrieve an emission from the sensor",
            "see_device_detail": "See sensor details",
            "success_get_first_emission": "The sensor has sent back a data ! You can check the quality of the data in the sensor details."
        },
        "modules": {
            "GNC_16SNSR": "16-tank automatic",
            "GNC_8SNSR": "8-tank automatic",
            "GNC_BMTR": "B-Meters",
            "GNC_BSTRM": "Heat recovery units",
            "GNC_BSTRM_3": "Three-inlet heat recovery units",
            "GNC_CHMRC": "Reprocessing plants",
            "GNC_CNT": "Counters",
            "GNC_DLVL": "Detergent Canister",
            "GNC_EMPT": "Blank generic module",
            "GNC_GAS": "Gas tanks",
            "GNC_GPS": "GPS tracking",
            "GNC_H2": "Hydrogen containers",
            "GNC_HIVE": "Beehives",
            "GNC_NETWORK": "Network equipment",
            "GNC_NOREA": "Norea liquid tanks",
            "GNC_PRESSURE": "Tank pressure",
            "GNC_SCLN": "Dilution plants",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Tanks (CNG)"
        },
        "need_help": "I need help",
        "next": "Next",
        "no_network": "No internet connection",
        "ok": "Ok",
        "perfect": "Perfect!",
        "selection_on_map": {
            "click_on_map": "Placing a point on a map",
            "no_address": "Can't find your address?"
        },
        "silo_type": {
            "brand_silo": "Silo brand",
            "brand_silo_required": "Required brand",
            "cylindrical_silo_conical_bottom": "Cylindrical silo with conical bottom",
            "cylindrical_tower_bottom": "Cylindrical tower with flat bottom",
            "diameter_part_right": "Diameter of the right part (in cm)",
            "diameter_required": "Required diameter",
            "error_get_silo_brands": "Impossible to recover silo marks.",
            "error_get_silo_models": "Impossible to recover the models of this brand of silo.",
            "height_required": "Height required",
            "height_total": "Total height (in cm)",
            "height_volume_silo": "What is the height and volume of your silo?",
            "know_brand_model": "I know the make and model of my silo?",
            "length_cone": "Cone height (in cm)",
            "length_cone_required": "Cone height required",
            "length_required": "Length required",
            "length_right_part": "Length straight part (in cm)",
            "length_width_minimum_cone": "Long/Diam min cone (in cm)",
            "model_silo": "Silo model",
            "model_silo_required": "Required model",
            "rectangular_silo_pyramidal_bottom": "Rectangular silo with pyramidal bottom",
            "rectangular_tower_flat_bottom": "Rectangular tower with flat bottom",
            "silo_type_required": "Type of silo required",
            "value_required": "Required value",
            "volume": "Volume (m3)",
            "volume_required": "Volume required",
            "width_min_cone": "Minimum cone width (in cm)",
            "width_min_cone_required": "Minimum width required",
            "width_required": "Required width",
            "width_right_part": "Width of the right side (in cm)"
        },
        "step_1": {
            "client_find_not_complete": "You have already tried to create a customer account with this email address, remember to finalize the account creation by clicking on the link received by email.",
            "client_find_with_mail": "Customer found with this email address.",
            "client_not_find_with_mail": "Customer not found with this email address.",
            "email_already_taken": "Email already used.",
            "email_invitation_inscription_sent": "An invitation email to register will be sent when the installation is completed",
            "email_not_valid": "Invalid email format",
            "email_required": "Email required",
            "error_during_association": "Error encountered when associating the sensor",
            "for_a_client": "For a client",
            "professional_account": "For the company",
            "send_registration_mail": "Send a registration e-mail",
            "title": "Contact",
            "welcome_to_installation": "Welcome to the installation of an inline sensor",
            "wish_associate_device_to": "I want to associate the sensor"
        },
        "step_2": {
            "camera_error": "Camera not supported",
            "device_cant_be_install": "The sensor cannot be installed.",
            "device_not_found": "Device not found",
            "device_number": "Sensor number",
            "device_series_number": "Serial number",
            "device_series_number_not_found": "Serial number not found.",
            "device_series_number_required": "Serial number required",
            "error_get_device": "Error encountered during sensor recovery",
            "inform_device_number": "Enter the sensor number",
            "message_associate_professional": "*Your sensor will be linked to the company, there will be no personal space (Sens). If you want to access your sensor via a personal space thank you to enter your email at the previous step.",
            "not_access_device_number": "I do not have access to the sensor number",
            "number_required": "Number required",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Boiler",
            "chaudiere": "Boiler",
            "choose_equipment_type": "Choose the type of equipment:",
            "cuve": "Tank",
            "device_associate_to": "The equipment is well associated with the business account",
            "equipment_type": "Type of equipment",
            "equipment_type_required": "Type of equipment required",
            "error_get_asset_types": "Error encountered when retrieving equipment types",
            "ground_water": "Water table",
            "hive": "Log",
            "no_equipment_type": "No type of equipment",
            "ouvrage": "Work",
            "reel": "Reel",
            "silo": "Silo",
            "title": "Type of equipment"
        },
        "step_4": {
            "appartement": "apartment",
            "boiler": "boiler",
            "chaudiere": "boiler",
            "cuve": "tank",
            "equipment": "equipment",
            "error_domicile": "Home not set up properly",
            "error_during_installation": "Error during installation",
            "ground_water": "water table",
            "machining_machine": "machining machine",
            "no_installation_for_this_type": "Installation not implemented for this type of equipment",
            "ouvrage": "work",
            "reel": "reel",
            "ruche": "Beehive",
            "silo": "silo",
            "title": "Configuration"
        },
        "tank_type": {
            "coated_cylindrical_format": "Coated cylindrical format",
            "error_get_tank_brands": "Impossible to recover tank marks",
            "error_get_tank_models": "Impossible to recover the models of this brand of tank.",
            "height": "Height (in cm)",
            "height_required": "Height required",
            "height_volume_tank": "What is the height and volume of your tank?",
            "ibc_format": "IBC format",
            "is_mobile": "Is mobile with wheel ?",
            "know_brand_model": "I know the model and brand of my tank?",
            "length_interior": "INSIDE length (in cm)",
            "length_interior_required": "Length required",
            "rectangular_format": "Rectangular format",
            "spherical_format": "Spherical format",
            "tank_brand": "Make of the tank",
            "tank_brand_required": "Required brand",
            "tank_model": "Model of the tank",
            "tank_model_required": "Required model",
            "tank_type_required": "Type of tank required",
            "vertical_cylindrical_format": "Vertical cylindrical format",
            "volume": "Volume (liter)",
            "volume_required": "Volume required",
            "width_interior": "INTERIOR width (in cm)",
            "width_interior_required": "Required width"
        },
        "validate": "Validate"
    },
    "es": {
        "activate": "Activar",
        "actualize": "Actualizaci\u00f3n",
        "add_client_dialog_component": {
            "add_client": "A\u00f1adir un cliente",
            "added_client": "Cliente a\u00f1adido",
            "client_reference": "Referencia del cliente",
            "client_space_name": "Nombre espacio significado",
            "company_name": "Nombre de la empresa",
            "siren": "SIRENA"
        },
        "asset_domicile": {
            "address_find": "Direcci\u00f3n encontrada",
            "bad_marker": "El punto colocado no corresponde a una direcci\u00f3n suficientemente precisa",
            "complete_address_equipment": "\u00bfCu\u00e1l es la direcci\u00f3n completa de su nuevo equipo?",
            "domicile": "Casas",
            "domicile_name": "Nombre de su casa",
            "domicile_required": "Residencia requerida",
            "error_browser_localisation": "Su navegador no soporta la geolocalizaci\u00f3n.",
            "error_geocode": "Error encontrado con el geoc\u00f3digo de google",
            "error_get_user_client_domiciles": "Error encontrado en la recuperaci\u00f3n de viviendas",
            "error_localisation_precision": "Imposible localizarlo correctamente.",
            "error_message_domicile": "La direcci\u00f3n no es lo suficientemente precisa",
            "inform_domicile_address": "Introduzca la direcci\u00f3n de su casa",
            "locate_me": "Geolocalizarme",
            "no_domicile": "No hay casa",
            "place_on_map": "Colocar un punto en un mapa",
            "refuse_localisation": "Ha rechazado la geolocalizaci\u00f3n.",
            "use_existing_address": "Utilizar una direcci\u00f3n existente",
            "use_new_address": "Utilizar una nueva direcci\u00f3n"
        },
        "asset_model_form_wrapper_component": {
            "content": "Contenido",
            "select_container_type": "Seleccione un tipo de contenedor"
        },
        "asset_specification": {
            "code_asset": "C\u00f3digo del activo (opcional)",
            "description": "Descripci\u00f3n",
            "equipment_information": "Informaci\u00f3n sobre el equipo",
            "equipment_specifications": "\u00bfCu\u00e1les son las caracter\u00edsticas de su equipo?",
            "error_get_liquids": "Imposible recuperar los l\u00edquidos.",
            "error_get_localisations": "No se pueden recuperar las ubicaciones.",
            "honey_type": "Tipo de miel",
            "honey_type_required": "Tipo de miel requerida",
            "intermediate_screw_pitch": "2 - Paso de tornillo intermedio",
            "large_screw_pitch": "3 - Rosca grande",
            "liquid_type": "Tipo de l\u00edquido",
            "liquid_type_required": "Tipo de l\u00edquido requerido",
            "localisation": "Ubicaci\u00f3n",
            "localisation_required": "Ubicaci\u00f3n requerida",
            "monitoring_consumption": "Control del consumo",
            "monitoring_filling": "Control de llenado",
            "monitoring_type": "Tipo de control",
            "monitoring_type_required": "Tipo de control necesario",
            "name_required": "Nombre requerido",
            "name_your_equipment": "Nombrar los equipos para facilitar su localizaci\u00f3n",
            "no_honey_type": "No hay ning\u00fan tipo de miel asociada",
            "no_liquid_type": "Sin tipo de l\u00edquido asociado",
            "no_screw_pitch": "Sin rosca",
            "offset": "Desplazamiento (cm)",
            "offset_required": "Desplazamiento necesario",
            "screw_pitch_number": "N\u00famero de hilo",
            "small_screw_pitch": "1 - Rosca peque\u00f1a"
        },
        "back": "Volver",
        "device_install": "Instalaci\u00f3n de sensores",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Cliente",
            "customer_link_to_equipment": "Cliente asociado al equipo",
            "name_equipment": "Nombre del equipo",
            "ref_equipment": "Equipamiento ref."
        },
        "email": "Email",
        "error": "Error interno del servidor",
        "error_display_option": "Tema no recuperado.",
        "fill_all_fields": "Por favor, rellene todos los campos",
        "generic_form_component": {
            "required_fields": "Campos obligatorios"
        },
        "generic_type": {
            "equipment_type_required": "Tipo de equipamiento necesario",
            "field_required": "Campo obligatorio",
            "problem_equipment_model": "No se han podido recuperar los modelos de este equipo.",
            "taring_bottom": "Tara baja",
            "taring_top": "Tara alta",
            "wrong_dateformat": "Formato de fecha incorrecto"
        },
        "global": {
            "activate": "Activar",
            "add": "A\u00f1adir",
            "cancel": "Cancelar",
            "confirm": "Confirme"
        },
        "installation_result": {
            "add_device_image": "A\u00f1adir una foto",
            "add_image_succeed": "Foto a\u00f1adida",
            "bad": "Bad",
            "camera_not_supported": "C\u00e1mara no compatible",
            "device_activation": "Lo \u00fanico que queda es activar su sensor {device_number} manualmente. Realice una medici\u00f3n manualmente para comprobar que el sensor est\u00e1 bien instalado.",
            "email_inscription_sent": "Se ha enviado un correo electr\u00f3nico de registro",
            "email_inscription_sent_at": "Se ha enviado un correo electr\u00f3nico a la direcci\u00f3n indicada",
            "error_add_image": "Error al a\u00f1adir la imagen",
            "error_email_inscription_sent": "Problema con el env\u00edo del correo electr\u00f3nico de registro",
            "finalize_inscription": "Para finalizar la inscripci\u00f3n y obtener los c\u00f3digos de acceso, haga clic en el correo electr\u00f3nico enviado.",
            "good": "Buena",
            "height_liquid": "Altura del l\u00edquido",
            "installation_success": "La instalaci\u00f3n ha ido bien.",
            "medium": "Medio",
            "network_state": "Estado de la red",
            "new_installation": "Realizar una nueva instalaci\u00f3n",
            "no_data": "No hay datos",
            "no_device_emission": "No hay emisi\u00f3n del sensor",
            "no_measure": "No hay mediciones reportadas",
            "realized_measure": "Medici\u00f3n realizada :",
            "reminder_to_check_emails": "Se envi\u00f3 un correo electr\u00f3nico de activaci\u00f3n a la direcci\u00f3n de correo electr\u00f3nico proporcionada en el paso 1 para acceder a los datos del sensor.",
            "very_good": "Muy bueno",
            "waiting_measure": "A la espera de la medici\u00f3n...",
            "weight": "Peso"
        },
        "installation_result_balance": {
            "activate_device": "Para comprobar que su b\u00e1scula funciona correctamente y asegurarse de que su colmenar est\u00e1 cubierto por la :",
            "activate_device_later": "Activar\u00e9 mi balanza m\u00e1s tarde",
            "device_activation": "Lo \u00fanico que queda es activar su balanza {device_number} manualmente. Realice una medici\u00f3n manualmente para comprobar que la balanza est\u00e1 bien instalada.",
            "error_get_first_emission": "No se puede recuperar un programa de la balanza",
            "see_device_detail": "Ver los detalles del balance",
            "step_1": "Abra su caso",
            "step_2": "Inserte la bater\u00eda en el compartimento de la bater\u00eda",
            "step_3": "Espere un doble pitido (si no hay pitido, espere 30 segundos antes de volver a intentarlo)",
            "step_4": "Cerrar la caja",
            "step_5": "Haga clic en activar para recoger los primeros datos de su balanza",
            "success_get_first_emission": "\u00a1La balanza ha devuelto un dato! Puedes comprobar la calidad de los datos en los detalles de la escala."
        },
        "installation_result_generic": {
            "activate_device": "Para comprobar el correcto funcionamiento de las lecturas, ponga el sensor en modo \"instalaci\u00f3n\". Para ello, pase el im\u00e1n alrededor del sensor para que aparezca el LED verde.",
            "activate_device_later": "Activar\u00e9 mi sensor m\u00e1s tarde",
            "device_activation": "Lo \u00fanico que queda es activar su sensor {device_number} manualmente. Realice una medici\u00f3n manualmente para comprobar que el sensor est\u00e1 instalado correctamente.",
            "error_get_first_emission": "No se ha podido recuperar una emisi\u00f3n del sensor",
            "see_device_detail": "Ver detalles del sensor",
            "success_get_first_emission": "\u00a1El sensor ha enviado un dato! Puedes comprobar la calidad de los datos en los detalles del sensor."
        },
        "modules": {
            "GNC_16SNSR": "16 dep\u00f3sitos autom\u00e1ticos",
            "GNC_8SNSR": "Autom\u00e1tico de 8 recipientes",
            "GNC_BMTR": "Medidores B",
            "GNC_BSTRM": "Recuperadores de calor",
            "GNC_BSTRM_3": "Recuperadores de calor de tres entradas",
            "GNC_CHMRC": "Plantas de reprocesado",
            "GNC_CNT": "Contadores",
            "GNC_DLVL": "Bote de detergente",
            "GNC_EMPT": "M\u00f3dulo gen\u00e9rico en blanco",
            "GNC_GAS": "Dep\u00f3sitos de gas",
            "GNC_GPS": "Seguimiento GPS",
            "GNC_H2": "Contenedores de hidr\u00f3geno",
            "GNC_HIVE": "Colmenas",
            "GNC_NETWORK": "Equipos de red",
            "GNC_NOREA": "Dep\u00f3sitos de l\u00edquidos Norea",
            "GNC_PRESSURE": "Presi\u00f3n del dep\u00f3sito",
            "GNC_SCLN": "Plantas de diluci\u00f3n",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Dep\u00f3sitos (GNC)"
        },
        "need_help": "Necesito ayuda",
        "next": "Siguiente",
        "no_network": "No hay conexi\u00f3n a Internet",
        "ok": "Ok",
        "perfect": "\u00a1Perfecto!",
        "selection_on_map": {
            "click_on_map": "Colocar un punto en un mapa",
            "no_address": "\u00bfNo encuentra su direcci\u00f3n?"
        },
        "silo_type": {
            "brand_silo": "Marca del silo",
            "brand_silo_required": "Marca requerida",
            "cylindrical_silo_conical_bottom": "Silo cil\u00edndrico con fondo c\u00f3nico",
            "cylindrical_tower_bottom": "Torre cil\u00edndrica con fondo plano",
            "diameter_part_right": "Di\u00e1metro del lado derecho (en cm)",
            "diameter_required": "Di\u00e1metro requerido",
            "error_get_silo_brands": "No se pueden recuperar las marcas de los silos.",
            "error_get_silo_models": "Imposible recuperar los modelos de esta marca de silo.",
            "height_required": "Altura requerida",
            "height_total": "Altura total (en cm)",
            "height_volume_silo": "\u00bfCu\u00e1l es la altura y el volumen de su silo?",
            "know_brand_model": "\u00bfConozco el modelo y la marca de mi silo?",
            "length_cone": "Altura del cono (en cm)",
            "length_cone_required": "Altura del cono requerida",
            "length_required": "Longitud requerida",
            "length_right_part": "Longitud parte recta (en cm)",
            "length_width_minimum_cone": "Longitud/Di\u00e1metro del cono (en cm)",
            "model_silo": "Modelo de silo",
            "model_silo_required": "Modelo requerido",
            "rectangular_silo_pyramidal_bottom": "Silo rectangular con fondo piramidal",
            "rectangular_tower_flat_bottom": "Torre rectangular con fondo plano",
            "silo_type_required": "Tipo de silo requerido",
            "value_required": "Valor requerido",
            "volume": "Volumen (m3)",
            "volume_required": "Volumen requerido",
            "width_min_cone": "Anchura m\u00ednima del cono (en cm)",
            "width_min_cone_required": "Anchura m\u00ednima requerida",
            "width_required": "Anchura requerida",
            "width_right_part": "Anchura del lado derecho (en cm)"
        },
        "step_1": {
            "client_find_not_complete": "Ya ha intentado crear una cuenta de cliente con esta direcci\u00f3n de correo electr\u00f3nico, recuerde finalizar la creaci\u00f3n de la cuenta haciendo clic en el enlace recibido por correo electr\u00f3nico.",
            "client_find_with_mail": "Cliente encontrado con esta direcci\u00f3n de correo electr\u00f3nico.",
            "client_not_find_with_mail": "Cliente no encontrado con esta direcci\u00f3n de correo electr\u00f3nico.",
            "email_already_taken": "Correo electr\u00f3nico ya utilizado.",
            "email_invitation_inscription_sent": "Una vez finalizada la instalaci\u00f3n, se enviar\u00e1 un correo electr\u00f3nico de invitaci\u00f3n para registrarse",
            "email_not_valid": "Formato de correo electr\u00f3nico no v\u00e1lido",
            "email_required": "Correo electr\u00f3nico requerido",
            "error_during_association": "Se ha producido un error al asociar el sensor",
            "for_a_client": "Para un cliente",
            "professional_account": "Para la empresa",
            "send_registration_mail": "Enviar un correo electr\u00f3nico de registro",
            "title": "Contacte con",
            "welcome_to_installation": "Bienvenido a la instalaci\u00f3n de un sensor en l\u00ednea",
            "wish_associate_device_to": "Quiero combinar el sensor"
        },
        "step_2": {
            "camera_error": "C\u00e1mara no compatible",
            "device_cant_be_install": "El sensor no se puede instalar.",
            "device_not_found": "Dispositivo no encontrado",
            "device_number": "N\u00famero de sensor",
            "device_series_number": "N\u00famero de serie",
            "device_series_number_not_found": "N\u00famero de serie no encontrado.",
            "device_series_number_required": "N\u00famero de serie requerido",
            "error_get_device": "Error encontrado durante la recuperaci\u00f3n del sensor",
            "inform_device_number": "Introduzca el n\u00famero de sensor",
            "message_associate_professional": "*Su sensor estar\u00e1 vinculado a la empresa, no habr\u00e1 espacio personal (Sens). Si desea acceder a su sensor a trav\u00e9s de un espacio personal, introduzca su direcci\u00f3n de correo electr\u00f3nico en el paso anterior.",
            "not_access_device_number": "No tengo acceso al n\u00famero de sensor",
            "number_required": "N\u00famero requerido",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Caldera",
            "chaudiere": "Caldera",
            "choose_equipment_type": "Elija el tipo de equipo:",
            "cuve": "Tanque",
            "device_associate_to": "El equipo est\u00e1 bien asociado a la cuenta de la empresa",
            "equipment_type": "Tipo de equipo",
            "equipment_type_required": "Tipo de equipamiento necesario",
            "error_get_asset_types": "Se ha producido un error al recuperar los tipos de equipos",
            "ground_water": "Tabla de agua",
            "hive": "Registro",
            "no_equipment_type": "Ning\u00fan tipo de equipo",
            "ouvrage": "Trabajo",
            "reel": "Carrete",
            "silo": "Silo",
            "title": "Tipo de equipo"
        },
        "step_4": {
            "appartement": "plano",
            "boiler": "caldera",
            "chaudiere": "caldera",
            "cuve": "tanque",
            "equipment": "equipo",
            "error_domicile": "El hogar no est\u00e1 correctamente configurado",
            "error_during_installation": "Error durante la instalaci\u00f3n",
            "ground_water": "tabla de agua",
            "machining_machine": "centro de mecanizado",
            "no_installation_for_this_type": "Instalaci\u00f3n no implementada para este tipo de equipos",
            "ouvrage": "trabajo",
            "reel": "carrete",
            "ruche": "Colmena",
            "silo": "silo",
            "title": "Configuraci\u00f3n"
        },
        "tank_type": {
            "coated_cylindrical_format": "Formato cil\u00edndrico recubierto",
            "error_get_tank_brands": "No se pueden recuperar las marcas del tanque",
            "error_get_tank_models": "Imposible recuperar modelos de esta marca de tanques.",
            "height": "Altura (en cm)",
            "height_required": "Altura requerida",
            "height_volume_tank": "\u00bfCu\u00e1l es la altura y el volumen de su tanque?",
            "ibc_format": "Formato del CIB",
            "is_mobile": "\u00bfEst\u00e1 montado en las ruedas?",
            "know_brand_model": "\u00bfS\u00e9 el modelo y la marca de mi tanque?",
            "length_interior": "Longitud interior (en cm)",
            "length_interior_required": "Longitud requerida",
            "rectangular_format": "Formato rectangular",
            "spherical_format": "Formato esf\u00e9rico",
            "tank_brand": "Marca del tanque",
            "tank_brand_required": "Marca requerida",
            "tank_model": "Modelo de tanque",
            "tank_model_required": "Modelo requerido",
            "tank_type_required": "Tipo de tanque requerido",
            "vertical_cylindrical_format": "Formato cil\u00edndrico vertical",
            "volume": "Volumen (litro)",
            "volume_required": "Volumen requerido",
            "width_interior": "Anchura interior (en cm)",
            "width_interior_required": "Anchura requerida"
        },
        "validate": "Validar"
    },
    "fr": {
        "activate": "Activer",
        "actualize": "Actualiser",
        "add_client_dialog_component": {
            "add_client": "Ajouter un client",
            "added_client": "Client ajout\u00e9",
            "client_reference": "R\u00e9f\u00e9rence client",
            "client_space_name": "Nom espace sens",
            "company_name": "Nom entreprise",
            "siren": "SIREN"
        },
        "asset_domicile": {
            "address_find": "Adresse trouv\u00e9e",
            "bad_marker": "Le point plac\u00e9 ne correspond pas \u00e0 une adresse assez pr\u00e9cise",
            "complete_address_equipment": "Quelle est l'adresse compl\u00e8te de votre nouvel \u00e9quipement",
            "domicile": "Domiciles",
            "domicile_name": "Nom de votre domicile",
            "domicile_required": "Domicile requis",
            "error_browser_localisation": "Votre navigateur ne supporte pas la g\u00e9olocalisation.",
            "error_geocode": "Erreur rencontr\u00e9 avec le g\u00e9ocode google",
            "error_get_user_client_domiciles": "Erreur rencontr\u00e9e lors de la r\u00e9cup\u00e9ration des domiciles",
            "error_localisation_precision": "Impossible de vous localisez correctement.",
            "error_message_domicile": "Adresse pas assez pr\u00e9cise",
            "inform_domicile_address": "Renseignez l'adresse de votre domicile",
            "locate_me": "Me g\u00e9olocaliser",
            "no_domicile": "Aucun domicile",
            "place_on_map": "Placer un point sur une carte",
            "refuse_localisation": "Vous avez refusez la g\u00e9olocalisation.",
            "use_existing_address": "Utiliser une adresse existante",
            "use_new_address": "Utiliser une nouvelle adresse"
        },
        "asset_model_form_wrapper_component": {
            "content": "Contenu",
            "select_container_type": "S\u00e9lectionner un type de contenant"
        },
        "asset_specification": {
            "code_asset": "Code asset (optionnel)",
            "description": "Description",
            "equipment_information": "Informations sur l'\u00e9quipement",
            "equipment_specifications": "Quels sont les caract\u00e9ristiques de votre \u00e9quipement",
            "error_get_liquids": "Impossible de r\u00e9cup\u00e9rer les liquides.",
            "error_get_localisations": "Impossible de r\u00e9cup\u00e9rer les localisations.",
            "honey_type": "Type de miel",
            "honey_type_required": "Type de miel requis",
            "intermediate_screw_pitch": "2 - Pas de vis interm\u00e9diaire",
            "large_screw_pitch": "3 - Grand pas de vis",
            "liquid_type": "Type de liquide",
            "liquid_type_required": "Type de liquide requis",
            "localisation": "Localisation",
            "localisation_required": "Localisation requise",
            "monitoring_consumption": "Surveillance de consommation",
            "monitoring_filling": "Surveillance de remplissage",
            "monitoring_type": "Type de suivi",
            "monitoring_type_required": "Type de suivi requis",
            "name_required": "Nom requis",
            "name_your_equipment": "Nommer votre \u00e9quipement pour le retrouver plus facilement",
            "no_honey_type": "Aucun type de miel associ\u00e9",
            "no_liquid_type": "Aucun type de liquide associ\u00e9",
            "no_screw_pitch": "Aucun pas de vis",
            "offset": "Offset (cm)",
            "offset_required": "Offset requis",
            "screw_pitch_number": "Num\u00e9ro pas de vis",
            "small_screw_pitch": "1 - Petit pas de vis"
        },
        "back": "Retour",
        "device_install": "Installation capteur",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Client",
            "customer_link_to_equipment": "Client associer \u00e0 l'\u00e9quipement",
            "name_equipment": "Nom \u00e9quipement",
            "ref_equipment": "R\u00e9f \u00e9quipement"
        },
        "email": "Email",
        "error": "Erreur interne du serveur",
        "error_display_option": "Th\u00e8me non r\u00e9cup\u00e9r\u00e9.",
        "fill_all_fields": "Veuillez remplir tous les champs",
        "generic_form_component": {
            "required_fields": "Champs requis"
        },
        "generic_type": {
            "equipment_type_required": "Type d'\u00e9quipement requis",
            "field_required": "Champ requis",
            "problem_equipment_model": "Impossible de r\u00e9cup\u00e9rer les mod\u00e8les de cet \u00e9quipement.",
            "taring_bottom": "Tarrage bas",
            "taring_top": "Tarrage haut",
            "wrong_dateformat": "Mauvais format de date"
        },
        "global": {
            "activate": "Activer",
            "add": "Ajouter",
            "cancel": "Annuler",
            "confirm": "Confirmer"
        },
        "installation_result": {
            "add_device_image": "Ajouter une photo",
            "add_image_succeed": "Photo ajout\u00e9",
            "bad": "Mauvais",
            "camera_not_supported": "Appareil photo non pris en charge",
            "device_activation": "Il ne reste plus qu'\u00e0 activer votre capteur {device_number} manuellement. Lancer une mesure manuellement pour v\u00e9rifier que la sonde est bien install\u00e9e.",
            "email_inscription_sent": "Un email d'inscription a \u00e9t\u00e9 envoy\u00e9",
            "email_inscription_sent_at": "Un email a \u00e9t\u00e9 envoy\u00e9 \u00e0 l\u2019adresse indiqu\u00e9e",
            "error_add_image": "Erreur lors de l'ajout de l'image",
            "error_email_inscription_sent": "Probl\u00e8me rencontr\u00e9 lors de l'envoi du mail d'inscription",
            "finalize_inscription": "Pour finaliser l'inscription et obtenir les codes d'acc\u00e8s, cliquez dans l'email qui a \u00e9t\u00e9 envoy\u00e9.",
            "good": "Bon",
            "height_liquid": "Hauteur du liquide",
            "installation_success": "L'installation s'est bien d\u00e9roul\u00e9e.",
            "medium": "Moyen",
            "network_state": "Etat r\u00e9seau",
            "new_installation": "Effectuer une nouvelle installation",
            "no_data": "Pas de donn\u00e9es",
            "no_device_emission": "Pas d'\u00e9mission du capteur",
            "no_measure": "Aucune mesure remont\u00e9e",
            "realized_measure": "Mesure r\u00e9alis\u00e9e : ",
            "reminder_to_check_emails": "Un email d'activation a \u00e9t\u00e9 envoy\u00e9 \u00e0 l'adresse email renseign\u00e9e \u00e0 l'\u00e9tape 1 pour acc\u00e9der aux donn\u00e9es du capteur.",
            "very_good": "Tr\u00e8s bon",
            "waiting_measure": "En attente de mesure...",
            "weight": "Poids"
        },
        "installation_result_balance": {
            "activate_device": "Afin de v\u00e9rifier le bon fonctionnement de votre balance et s'assurer que votre rucher est couvert par le r\u00e9seau : ",
            "activate_device_later": "J'activerai ma balance plus tard",
            "device_activation": "Il ne reste plus qu'\u00e0 activer votre balance {device_number} manuellement. Lancer une mesure manuellement pour v\u00e9rifier que la balance est bien install\u00e9e.",
            "error_get_first_emission": "Impossible de r\u00e9cup\u00e9rer une \u00e9mission de la balance",
            "see_device_detail": "Voir le d\u00e9tail de la balance",
            "step_1": "Ouvrez votre bo\u00eetier ",
            "step_2": "Ins\u00e9rez la pile dans son logement ",
            "step_3": "Attendez l'obtention d'un double bip sonore (si pas de bip, veuillez attendre 30 secondes avant de recommencer l'op\u00e9ration)",
            "step_4": "Refermez le bo\u00eetier ",
            "step_5": "Cliquer sur activer afin de collecter la premi\u00e8re donn\u00e9e de votre balance",
            "success_get_first_emission": "La balance \u00e0 bien remont\u00e9e une donn\u00e9e ! Vous pouvez v\u00e9rifier la qualit\u00e9 de la donn\u00e9e dans le d\u00e9tail de la balance."
        },
        "installation_result_generic": {
            "activate_device": "Pour v\u00e9rifier le bon fonctionnement des relev\u00e9s veuillez mettre le capteur en mode \"installation\". Pour cela passer l'aimant autour du capteur pour faire appara\u00eetre la LED verte.",
            "activate_device_later": "J'activerai mon capteur plus tard",
            "device_activation": "Il ne reste plus qu'\u00e0 activer votre capteur {device_number} manuellement. Lancer une mesure manuellement pour v\u00e9rifier que la capteur est bien install\u00e9.",
            "error_get_first_emission": "Impossible de r\u00e9cup\u00e9rer une \u00e9mission du capteur",
            "see_device_detail": "Voir le d\u00e9tail du capteur",
            "success_get_first_emission": "Le capteur \u00e0 bien remont\u00e9 une donn\u00e9e ! Vous pouvez v\u00e9rifier la qualit\u00e9 de celle-ci dans le d\u00e9tail du capteur."
        },
        "modules": {
            "GNC_16SNSR": "Automate 16 cuves",
            "GNC_8SNSR": "Automate 8 cuves",
            "GNC_BMTR": "Compteurs (B-Meters)",
            "GNC_BSTRM": "R\u00e9cup\u00e9rateurs de chaleur",
            "GNC_BSTRM_3": "R\u00e9cup\u00e9rateurs de chaleur trois entr\u00e9es",
            "GNC_CHMRC": "Centrales de retraitement",
            "GNC_CNT": "Compteurs",
            "GNC_DLVL": "Detergent Canister",
            "GNC_EMPT": "Module g\u00e9n\u00e9rique vierge",
            "GNC_GAS": "Citernes de Gaz",
            "GNC_GPS": "Tracking GPS",
            "GNC_H2": "Conteneurs d'hydrog\u00e8ne",
            "GNC_HIVE": "Ruches",
            "GNC_NETWORK": "Equipements r\u00e9seaux",
            "GNC_NOREA": "Cuves liquide Norea",
            "GNC_PRESSURE": "Pression citerne",
            "GNC_SCLN": "Centrales de dilution",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Cuves (GNC)"
        },
        "need_help": "J'ai besoin d'aide",
        "next": "Suivant",
        "no_network": "Aucune connexion internet",
        "ok": "Ok",
        "perfect": "Parfait !",
        "selection_on_map": {
            "click_on_map": "Placer un point sur une carte",
            "no_address": "Vous ne trouvez pas votre adresse ?"
        },
        "silo_type": {
            "brand_silo": "Marque du silo",
            "brand_silo_required": "Marque requise",
            "cylindrical_silo_conical_bottom": "Silo cylindrique \u00e0 fond conique",
            "cylindrical_tower_bottom": "Tour cylindrique \u00e0 fond plat",
            "diameter_part_right": "Diam\u00e8tre partie droite (en cm)",
            "diameter_required": "Diam\u00e8tre requis",
            "error_get_silo_brands": "Impossible de r\u00e9cup\u00e9rer les marques de silo.",
            "error_get_silo_models": "Impossible de r\u00e9cup\u00e9rer les mod\u00e8les de cette marque de silo.",
            "height_required": "Hauteur requise",
            "height_total": "Hauteur totale (en cm)",
            "height_volume_silo": "Quel est la hauteur et le volume de votre silo ?",
            "know_brand_model": "Je connais le mod\u00e8le et la marque de mon silo ?",
            "length_cone": "Hauteur c\u00f4ne (en cm)",
            "length_cone_required": "Hauteur c\u00f4ne requis",
            "length_required": "Longueur requise",
            "length_right_part": "Longueur partie droite (en cm)",
            "length_width_minimum_cone": "Long/Diam min c\u00f4ne (en cm)",
            "model_silo": "Mod\u00e8le du silo",
            "model_silo_required": "Mod\u00e8le requis",
            "rectangular_silo_pyramidal_bottom": "Silo rectangulaire \u00e0 fond pyramidal",
            "rectangular_tower_flat_bottom": "Tour rectangulaire \u00e0 fond plat",
            "silo_type_required": "Type de silo requis",
            "value_required": "Valeur requise",
            "volume": "Volume (m3)",
            "volume_required": "Volume requis",
            "width_min_cone": "Largeur minimum c\u00f4ne (en cm)",
            "width_min_cone_required": "Largeur minimum requise",
            "width_required": "Largeur requise",
            "width_right_part": "Largeur partie droite (en cm)"
        },
        "step_1": {
            "client_find_not_complete": "Vous avez d\u00e9j\u00e0 tent\u00e9 de cr\u00e9er un compte client avec cette adresse e-mail, pensez \u00e0 finaliser la cr\u00e9ation du compte en cliquant sur le lien re\u00e7u par email.",
            "client_find_with_mail": "Client trouv\u00e9 avec cette adresse email.",
            "client_not_find_with_mail": "Client non trouv\u00e9 avec cette adresse email.",
            "email_already_taken": "Email d\u00e9j\u00e0 utilis\u00e9.",
            "email_invitation_inscription_sent": "Un email d'invitation pour s'inscrire sera envoy\u00e9 lorsque l'installation sera finie",
            "email_not_valid": "Format d'email invalide",
            "email_required": "Email requis",
            "error_during_association": "Erreur rencontr\u00e9e lors de l'association du capteur",
            "for_a_client": "Pour un client",
            "professional_account": "Pour l'entreprise",
            "send_registration_mail": "Envoyer un mail d'inscription",
            "title": "Contact",
            "welcome_to_installation": "Bienvenue sur l'installation d'un capteur en ligne",
            "wish_associate_device_to": "Je souhaite associer le capteur"
        },
        "step_2": {
            "camera_error": "Appareil photo non pris en charge",
            "device_cant_be_install": "Le capteur ne peux pas \u00eatre install\u00e9.",
            "device_not_found": "Appareil non trouv\u00e9",
            "device_number": "Num\u00e9ro de capteur",
            "device_series_number": "Num\u00e9ro de s\u00e9rie",
            "device_series_number_not_found": "Num\u00e9ro de s\u00e9rie non trouv\u00e9.",
            "device_series_number_required": "Num\u00e9ro de s\u00e9rie requis",
            "error_get_device": "Erreur rencontr\u00e9e lors de la r\u00e9cup\u00e9ration du capteur",
            "inform_device_number": "Renseigner le num\u00e9ro de capteur",
            "message_associate_professional": "*Votre capteur sera li\u00e9 \u00e0 l'entreprise il n'y aura pas d'espace personnel (Sens). Si vous souhaitez acc\u00e9der \u00e0 votre capteur via un espace personnel merci de saisir de votre mail \u00e0 l'\u00e9tape pr\u00e9c\u00e9dente.",
            "not_access_device_number": "Je n'ai pas acc\u00e8s au num\u00e9ro du capteur",
            "number_required": "Num\u00e9ro requis",
            "title": "Capteur"
        },
        "step_3": {
            "boiler": "Chaudi\u00e8re",
            "chaudiere": "Chaudi\u00e8re",
            "choose_equipment_type": "Choisir le type d'\u00e9quipement :",
            "cuve": "Cuve",
            "device_associate_to": "L'\u00e9quipement est bien associ\u00e9 au compte professionnel",
            "equipment_type": "Type d'\u00e9quipement",
            "equipment_type_required": "Type d'\u00e9quipement requis",
            "error_get_asset_types": "Erreur rencontr\u00e9e lors de la r\u00e9cup\u00e9ration des types d'\u00e9quipements",
            "ground_water": "Nappe phr\u00e9atique",
            "hive": "R\u00fbche",
            "no_equipment_type": "Aucun de type d'\u00e9quipement",
            "ouvrage": "Ouvrage",
            "reel": "Enrouleur",
            "silo": "Silo",
            "title": "Type d'\u00e9quipement"
        },
        "step_4": {
            "appartement": "appartement",
            "boiler": "chaudi\u00e8re",
            "chaudiere": "chaudi\u00e8re",
            "cuve": "cuve",
            "equipment": "equipement",
            "error_domicile": "Domicile mal param\u00e9tr\u00e9",
            "error_during_installation": "Erreur lors de l'installation",
            "ground_water": "nappe phr\u00e9atique",
            "machining_machine": "machine d'usinage",
            "no_installation_for_this_type": "Installation non impl\u00e9ment\u00e9e pour ce type d'\u00e9quipement",
            "ouvrage": "ouvrage",
            "reel": "enrouleur",
            "ruche": "Ruche",
            "silo": "silo",
            "title": "Configuration"
        },
        "tank_type": {
            "coated_cylindrical_format": "Format cylindrique couch\u00e9",
            "error_get_tank_brands": "Impossible de r\u00e9cup\u00e9rer les marques de cuves",
            "error_get_tank_models": "Impossible de r\u00e9cup\u00e9rer les mod\u00e8les de cette marque de cuve.",
            "height": "Hauteur (en cm)",
            "height_required": "Hauteur requis",
            "height_volume_tank": "Quel est la hauteur et le volume de votre cuve ?",
            "ibc_format": "Format IBC",
            "is_mobile": "Est mobile \u00e0 roue ?",
            "know_brand_model": "Je connais le mod\u00e8le et la marque de ma cuve ?",
            "length_interior": "Longueur INTERIEURE (en cm)",
            "length_interior_required": "Longueur requise",
            "rectangular_format": "Format rectangulaire",
            "spherical_format": "Format sph\u00e9rique",
            "tank_brand": "Marque de la cuve",
            "tank_brand_required": "Marque requise",
            "tank_model": "Mod\u00e8le de la cuve",
            "tank_model_required": "Mod\u00e8le requis",
            "tank_type_required": "Type de cuve requise",
            "vertical_cylindrical_format": "Format cylindrique vertical",
            "volume": "Volume (litre)",
            "volume_required": "Volume requis",
            "width_interior": "Largeur INTERIEURE (en cm)",
            "width_interior_required": "Largeur requise"
        },
        "validate": "Valider"
    },
    "ie": {
        "activate": "Activate",
        "actualize": "Refresh",
        "add_client_dialog_component": {
            "add_client": "Add a customer",
            "added_client": "Customer added",
            "client_reference": "Customer reference",
            "client_space_name": "Name space meaning",
            "company_name": "Company name",
            "siren": "SIREN"
        },
        "asset_domicile": {
            "address_find": "Address found",
            "bad_marker": "The point placed does not correspond to a precise enough address",
            "complete_address_equipment": "What is the full address of your new equipment",
            "domicile": "Homes",
            "domicile_name": "Name of your home",
            "domicile_required": "Required residence",
            "error_browser_localisation": "Your browser does not support geolocation.",
            "error_geocode": "Error encountered with the google geocode",
            "error_get_user_client_domiciles": "Error encountered during the recovery of homes",
            "error_localisation_precision": "Impossible to locate you correctly.",
            "error_message_domicile": "Address not precise enough",
            "inform_domicile_address": "Enter your home address",
            "locate_me": "Geolocate me",
            "no_domicile": "No home",
            "place_on_map": "Placing a point on a map",
            "refuse_localisation": "You have refused the geolocation.",
            "use_existing_address": "Use an existing address",
            "use_new_address": "Use a new address"
        },
        "asset_model_form_wrapper_component": {
            "content": "Content",
            "select_container_type": "Select a container type"
        },
        "asset_specification": {
            "code_asset": "Asset code (optional)",
            "description": "Description",
            "equipment_information": "Equipment information",
            "equipment_specifications": "What are the characteristics of your equipment",
            "error_get_liquids": "Impossible to recover liquids.",
            "error_get_localisations": "Unable to retrieve locations.",
            "honey_type": "Type of honey",
            "honey_type_required": "Type of honey required",
            "intermediate_screw_pitch": "2 - Intermediate screw pitch",
            "large_screw_pitch": "3 - Large screw thread",
            "liquid_type": "Type of liquid",
            "liquid_type_required": "Type of liquid required",
            "localisation": "Location",
            "localisation_required": "Location required",
            "monitoring_consumption": "Consumption monitoring",
            "monitoring_filling": "Filling monitoring",
            "monitoring_type": "Type of monitoring",
            "monitoring_type_required": "Type of follow-up required",
            "name_required": "Name required",
            "name_your_equipment": "Name your equipment to find it more easily",
            "no_honey_type": "No honey type associated",
            "no_liquid_type": "No associated liquid type",
            "no_screw_pitch": "No screw thread",
            "offset": "Offset (cm)",
            "offset_required": "Offset required",
            "screw_pitch_number": "Thread number",
            "small_screw_pitch": "1 - Small screw thread"
        },
        "back": "Back",
        "device_install": "Sensor installation",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Customer",
            "customer_link_to_equipment": "Customer associated with equipment",
            "name_equipment": "Equipment name",
            "ref_equipment": "Equipment ref."
        },
        "email": "Email",
        "error": "Internal server error",
        "error_display_option": "Theme not recovered.",
        "fill_all_fields": "Please fill in all fields",
        "generic_form_component": {
            "required_fields": "Required fields"
        },
        "generic_type": {
            "equipment_type_required": "Type of equipment required",
            "field_required": "Required field",
            "problem_equipment_model": "Impossible to recover the models of this equipment.",
            "taring_bottom": "Low tare",
            "taring_top": "High tare",
            "wrong_dateformat": "Wrong date format"
        },
        "global": {
            "activate": "Activate",
            "add": "Add",
            "cancel": "Cancel",
            "confirm": "Confirm"
        },
        "installation_result": {
            "add_device_image": "Add a photo",
            "add_image_succeed": "Photo added",
            "bad": "Bad",
            "camera_not_supported": "Camera not supported",
            "device_activation": "All that remains is to activate your {device_number} sensor manually. Launch a measurement manually to check that the sensor is well installed.",
            "email_inscription_sent": "A registration email has been sent",
            "email_inscription_sent_at": "An email was sent to the address indicated",
            "error_add_image": "Error while adding the image",
            "error_email_inscription_sent": "Problem encountered when sending the registration email",
            "finalize_inscription": "To finalize the registration and obtain the access codes, click in the email that was sent.",
            "good": "Good",
            "height_liquid": "Height of the liquid",
            "installation_success": "The installation went well.",
            "medium": "Meduim",
            "network_state": "Network status",
            "new_installation": "Perform a new installation",
            "no_data": "No data",
            "no_device_emission": "No emission from the sensor",
            "no_measure": "No measurements reported",
            "realized_measure": "Measurement performed:",
            "reminder_to_check_emails": "An activation email has been sent to the email address provided in step 1 to access the sensor data.",
            "very_good": "Very good",
            "waiting_measure": "Waiting for a measure...",
            "weight": "Weight"
        },
        "installation_result_balance": {
            "activate_device": "In order to verify the proper functioning of your scale and ensure that your apiary is covered by the network :",
            "activate_device_later": "I'll activate my scale later",
            "device_activation": "All that remains is to activate your {device_number} scale manually. Run a measurement manually to check that the scale is properly installed.",
            "error_get_first_emission": "Unable to retrieve a program from the scale",
            "see_device_detail": "See the details of the balance",
            "step_1": "Open your case",
            "step_2": "Insert the battery into the battery compartment",
            "step_3": "Wait for a double beep (if no beep, please wait 30 seconds before trying again)",
            "step_4": "Close the box",
            "step_5": "Click on activate to collect the first data from your scale",
            "success_get_first_emission": "The scale has returned a data! You can check the quality of the data in the details of the scale."
        },
        "installation_result_generic": {
            "activate_device": "To check the correct functioning of the readings please put the sensor in \"installation\" mode. To do this, pass the magnet around the sensor to make the green LED appear.",
            "activate_device_later": "I will activate my sensor later",
            "device_activation": "You just have to activate your {device_number} sensor manually. Launch a measurement manually to check that the sensor is well installed.",
            "error_get_first_emission": "Unable to retrieve an emission from the sensor",
            "see_device_detail": "See sensor details",
            "success_get_first_emission": "The sensor has sent back a data ! You can check the quality of the data in the sensor details."
        },
        "modules": {
            "GNC_16SNSR": "16-tank automatic",
            "GNC_8SNSR": "8-vessel automatic",
            "GNC_BMTR": "B-Meters",
            "GNC_BSTRM": "Heat recovery units",
            "GNC_BSTRM_3": "Three-inlet heat recovery units",
            "GNC_CHMRC": "Reprocessing plants",
            "GNC_CNT": "Counters",
            "GNC_DLVL": "Detergent Canister",
            "GNC_EMPT": "Blank generic module",
            "GNC_GAS": "Gas tanks",
            "GNC_GPS": "GPS tracking",
            "GNC_H2": "Hydrogen containers",
            "GNC_HIVE": "Beehives",
            "GNC_NETWORK": "Network equipment",
            "GNC_NOREA": "Norea liquid tanks",
            "GNC_PRESSURE": "Tank pressure",
            "GNC_SCLN": "Dilution plants",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Tanks (CNG)"
        },
        "need_help": "I need help",
        "next": "Next",
        "no_network": "No internet connection",
        "ok": "Ok",
        "perfect": "Perfect!",
        "selection_on_map": {
            "click_on_map": "Placing a point on a map",
            "no_address": "Can't find your address?"
        },
        "silo_type": {
            "brand_silo": "Silo brand",
            "brand_silo_required": "Required brand",
            "cylindrical_silo_conical_bottom": "Cylindrical silo with conical bottom",
            "cylindrical_tower_bottom": "Cylindrical tower with flat bottom",
            "diameter_part_right": "Diameter of the right part (in cm)",
            "diameter_required": "Required diameter",
            "error_get_silo_brands": "Impossible to recover silo marks.",
            "error_get_silo_models": "Impossible to recover the models of this brand of silo.",
            "height_required": "Height required",
            "height_total": "Total height (in cm)",
            "height_volume_silo": "What is the height and volume of your silo?",
            "know_brand_model": "I know the make and model of my silo?",
            "length_cone": "Cone height (in cm)",
            "length_cone_required": "Cone height required",
            "length_required": "Length required",
            "length_right_part": "Length straight part (in cm)",
            "length_width_minimum_cone": "Long/Diam min cone (in cm)",
            "model_silo": "Silo model",
            "model_silo_required": "Required model",
            "rectangular_silo_pyramidal_bottom": "Rectangular silo with pyramidal bottom",
            "rectangular_tower_flat_bottom": "Rectangular tower with flat bottom",
            "silo_type_required": "Type of silo required",
            "value_required": "Required value",
            "volume": "Volume (m3)",
            "volume_required": "Volume required",
            "width_min_cone": "Minimum cone width (in cm)",
            "width_min_cone_required": "Minimum width required",
            "width_required": "Required width",
            "width_right_part": "Width of the right side (in cm)"
        },
        "step_1": {
            "client_find_not_complete": "You have already tried to create a customer account with this email address, remember to finalize the account creation by clicking on the link received by email.",
            "client_find_with_mail": "Customer found with this email address.",
            "client_not_find_with_mail": "Customer not found with this email address.",
            "email_already_taken": "Email already used.",
            "email_invitation_inscription_sent": "An invitation email to register will be sent when the installation is completed",
            "email_not_valid": "Invalid email format",
            "email_required": "Email required",
            "error_during_association": "Error encountered when associating the sensor",
            "for_a_client": "For a client",
            "professional_account": "For the company",
            "send_registration_mail": "Send a registration email",
            "title": "Contact",
            "welcome_to_installation": "Welcome to the installation of an inline sensor",
            "wish_associate_device_to": "I want to associate the sensor"
        },
        "step_2": {
            "camera_error": "Camera not supported",
            "device_cant_be_install": "The sensor cannot be installed.",
            "device_not_found": "Device not found",
            "device_number": "Sensor number",
            "device_series_number": "Serial number",
            "device_series_number_not_found": "Serial number not found.",
            "device_series_number_required": "Serial number required",
            "error_get_device": "Error encountered during sensor recovery",
            "inform_device_number": "Enter the sensor number",
            "message_associate_professional": "*Your sensor will be linked to the company, there will be no personal space (Sens). If you want to access your sensor via a personal space thank you to enter your email at the previous step.",
            "not_access_device_number": "I do not have access to the sensor number",
            "number_required": "Number required",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Boiler",
            "chaudiere": "Boiler",
            "choose_equipment_type": "Choose the type of equipment:",
            "cuve": "Tank",
            "device_associate_to": "The equipment is well associated with the business account",
            "equipment_type": "Type of equipment",
            "equipment_type_required": "Type of equipment required",
            "error_get_asset_types": "Error encountered when retrieving equipment types",
            "ground_water": "Water table",
            "hive": "Log",
            "no_equipment_type": "No type of equipment",
            "ouvrage": "Work",
            "reel": "Reel",
            "silo": "Silo",
            "title": "Type of equipment"
        },
        "step_4": {
            "appartement": "apartment",
            "boiler": "boiler",
            "chaudiere": "boiler",
            "cuve": "tank",
            "equipment": "equipment",
            "error_domicile": "Home not set up properly",
            "error_during_installation": "Error during installation",
            "ground_water": "water table",
            "machining_machine": "machining machine",
            "no_installation_for_this_type": "Installation not implemented for this type of equipment",
            "ouvrage": "work",
            "reel": "reel",
            "ruche": "Beehive",
            "silo": "silo",
            "title": "Configuration"
        },
        "tank_type": {
            "coated_cylindrical_format": "Coated cylindrical format",
            "error_get_tank_brands": "Impossible to recover tank marks",
            "error_get_tank_models": "Impossible to recover the models of this brand of tank.",
            "height": "Height (in cm)",
            "height_required": "Height required",
            "height_volume_tank": "What is the height and volume of your tank?",
            "ibc_format": "IBC format",
            "is_mobile": "Is mobile with wheel ?",
            "know_brand_model": "I know the model and brand of my tank?",
            "length_interior": "INSIDE length (in cm)",
            "length_interior_required": "Length required",
            "rectangular_format": "Rectangular format",
            "spherical_format": "Spherical format",
            "tank_brand": "Make of the tank",
            "tank_brand_required": "Required brand",
            "tank_model": "Model of the tank",
            "tank_model_required": "Required model",
            "tank_type_required": "Type of tank required",
            "vertical_cylindrical_format": "Vertical cylindrical format",
            "volume": "Volume (liter)",
            "volume_required": "Volume required",
            "width_interior": "INTERIOR width (in cm)",
            "width_interior_required": "Required width"
        },
        "validate": "Validate"
    },
    "it": {
        "activate": "Attivare",
        "actualize": "Aggiornamento",
        "add_client_dialog_component": {
            "add_client": "Aggiungere un cliente",
            "added_client": "Cliente aggiunto",
            "client_reference": "Riferimento del cliente",
            "client_space_name": "Significato dello spazio dei nomi",
            "company_name": "Nome della societ\u00e0",
            "siren": "SIRENA"
        },
        "asset_domicile": {
            "address_find": "Indirizzo trovato",
            "bad_marker": "Il punto posizionato non corrisponde a un indirizzo sufficientemente preciso",
            "complete_address_equipment": "Qual \u00e8 l'indirizzo completo della nuova attrezzatura?",
            "domicile": "Case",
            "domicile_name": "Nome della vostra casa",
            "domicile_required": "Residenza richiesta",
            "error_browser_localisation": "Il vostro browser non supporta la geolocalizzazione.",
            "error_geocode": "Errore riscontrato con il geocodice di Google",
            "error_get_user_client_domiciles": "Errore riscontrato nel recupero delle abitazioni",
            "error_localisation_precision": "Impossibile localizzarvi correttamente.",
            "error_message_domicile": "Indirizzo non abbastanza preciso",
            "inform_domicile_address": "Inserire l'indirizzo di casa",
            "locate_me": "Geolocalizzatemi",
            "no_domicile": "Nessuna casa",
            "place_on_map": "Posizionare un punto su una mappa",
            "refuse_localisation": "L'utente ha rifiutato la geolocalizzazione.",
            "use_existing_address": "Utilizzare un indirizzo esistente",
            "use_new_address": "Utilizzare un nuovo indirizzo"
        },
        "asset_model_form_wrapper_component": {
            "content": "Contenuto",
            "select_container_type": "Selezionare un tipo di contenitore"
        },
        "asset_specification": {
            "code_asset": "Codice attivit\u00e0 (facoltativo)",
            "description": "Descrizione",
            "equipment_information": "Informazioni sull'attrezzatura",
            "equipment_specifications": "Quali sono le caratteristiche della vostra attrezzatura?",
            "error_get_liquids": "Impossibile recuperare i liquidi.",
            "error_get_localisations": "Impossibile recuperare le posizioni.",
            "honey_type": "Tipo di miele",
            "honey_type_required": "Tipo di miele richiesto",
            "intermediate_screw_pitch": "2 - Passo vite intermedio",
            "large_screw_pitch": "3 - Filetto a vite grande",
            "liquid_type": "Tipo di liquido",
            "liquid_type_required": "Tipo di liquido richiesto",
            "localisation": "Posizione",
            "localisation_required": "Posizione richiesta",
            "monitoring_consumption": "Monitoraggio dei consumi",
            "monitoring_filling": "Monitoraggio del riempimento",
            "monitoring_type": "Tipo di monitoraggio",
            "monitoring_type_required": "Tipo di monitoraggio richiesto",
            "name_required": "Nome richiesto",
            "name_your_equipment": "Assegnare un nome all'apparecchiatura per facilitarne l'individuazione",
            "no_honey_type": "Nessun tipo di miele associato",
            "no_liquid_type": "Nessun tipo di liquido associato",
            "no_screw_pitch": "Nessuna filettatura",
            "offset": "Offset (cm)",
            "offset_required": "Offset richiesto",
            "screw_pitch_number": "Numero di thread",
            "small_screw_pitch": "1 - Filettatura piccola"
        },
        "back": "Indietro",
        "device_install": "Installazione del sensore",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Cliente",
            "customer_link_to_equipment": "Cliente associato all'apparecchiatura",
            "name_equipment": "Nome dell'apparecchiatura",
            "ref_equipment": "Attrezzatura rif."
        },
        "email": "Email",
        "error": "Errore interno del server",
        "error_display_option": "Tema non recuperato.",
        "fill_all_fields": "Compilare tutti i campi",
        "generic_form_component": {
            "required_fields": "Campi obbligatori"
        },
        "generic_type": {
            "equipment_type_required": "Tipo di attrezzatura richiesta",
            "field_required": "Campo obbligatorio",
            "problem_equipment_model": "Impossibile recuperare i modelli di questa apparecchiatura.",
            "taring_bottom": "Tara bassa",
            "taring_top": "Tara alta",
            "wrong_dateformat": "Formato della data errato"
        },
        "global": {
            "activate": "Attivare",
            "add": "Aggiungi",
            "cancel": "Annullamento",
            "confirm": "Confermare"
        },
        "installation_result": {
            "add_device_image": "Aggiungi una foto",
            "add_image_succeed": "Foto aggiunta",
            "bad": "Male",
            "camera_not_supported": "Fotocamera non supportata",
            "device_activation": "Non resta che attivare manualmente il sensore {device_number}. Eseguire una misurazione manuale per verificare che il sensore sia installato correttamente.",
            "email_inscription_sent": "\u00c8 stata inviata un'e-mail di registrazione",
            "email_inscription_sent_at": "\u00c8 stata inviata un'e-mail all'indirizzo indicato",
            "error_add_image": "Errore durante l'aggiunta dell'immagine",
            "error_email_inscription_sent": "Problema riscontrato durante l'invio dell'e-mail di registrazione",
            "finalize_inscription": "Per completare la registrazione e ottenere i codici di accesso, cliccare sull'e-mail inviata.",
            "good": "Buono",
            "height_liquid": "Altezza del liquido",
            "installation_success": "L'installazione \u00e8 andata bene.",
            "medium": "Medio",
            "network_state": "Stato della rete",
            "new_installation": "Esecuzione di una nuova installazione",
            "no_data": "Nessun dato",
            "no_device_emission": "Nessuna emissione dal sensore",
            "no_measure": "Nessuna misura riportata",
            "realized_measure": "Misurazione effettuata :",
            "reminder_to_check_emails": "All'indirizzo e-mail fornito al punto 1 \u00e8 stata inviata un'e-mail di attivazione per accedere ai dati del sensore.",
            "very_good": "Molto buono",
            "waiting_measure": "In attesa della misurazione...",
            "weight": "Peso"
        },
        "installation_result_balance": {
            "activate_device": "Per verificare il corretto funzionamento della bilancia e per assicurarsi che il proprio apiario sia coperto dalla :",
            "activate_device_later": "Attivo la mia bilancia pi\u00f9 tardi",
            "device_activation": "Non resta che attivare manualmente la bilancia {device_number}. Eseguire una misurazione manuale per verificare che la bilancia sia installata correttamente.",
            "error_get_first_emission": "Impossibile recuperare un programma dalla bilancia",
            "see_device_detail": "Vedi i dettagli del bilancio",
            "step_1": "Aprire il caso",
            "step_2": "Inserire la batteria nel vano batteria",
            "step_3": "Attendere un doppio segnale acustico (se non c'\u00e8, attendere 30 secondi prima di riprovare).",
            "step_4": "Chiudere la scatola",
            "step_5": "Fare clic su attiva per raccogliere i primi dati dalla bilancia.",
            "success_get_first_emission": "La bilancia ha restituito un dato! \u00c8 possibile verificare la qualit\u00e0 dei dati nei dettagli della scala."
        },
        "installation_result_generic": {
            "activate_device": "Per verificare il corretto funzionamento delle letture, mettere il sensore in modalit\u00e0 \"installazione\". A tal fine, passare il magnete attorno al sensore per far apparire il LED verde.",
            "activate_device_later": "Attiver\u00f2 il mio sensore pi\u00f9 tardi",
            "device_activation": "Non resta che attivare manualmente il sensore {device_number}. Eseguire una misurazione manuale per verificare che il sensore sia installato correttamente.",
            "error_get_first_emission": "Impossibile recuperare un'emissione dal sensore",
            "see_device_detail": "Vedere i dettagli del sensore",
            "success_get_first_emission": "Il sensore ha inviato un dato! \u00c8 possibile verificare la qualit\u00e0 dei dati nei dettagli del sensore."
        },
        "modules": {
            "GNC_16SNSR": "Automatico a 16 serbatoi",
            "GNC_8SNSR": "Automatico a 8 vasche",
            "GNC_BMTR": "Metri B",
            "GNC_BSTRM": "Unit\u00e0 di recupero del calore",
            "GNC_BSTRM_3": "Recupero di calore a tre ingressi",
            "GNC_CHMRC": "Impianti di ritrattamento",
            "GNC_CNT": "Contatori",
            "GNC_DLVL": "Contenitore per detersivo",
            "GNC_EMPT": "Modulo generico vuoto",
            "GNC_GAS": "Serbatoi di gas",
            "GNC_GPS": "Tracciamento GPS",
            "GNC_H2": "Contenitori di idrogeno",
            "GNC_HIVE": "Alveari",
            "GNC_NETWORK": "Apparecchiature di rete",
            "GNC_NOREA": "Serbatoi per liquidi Norea",
            "GNC_PRESSURE": "Pressione del serbatoio",
            "GNC_SCLN": "Impianti di diluizione",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Serbatoi (CNG)"
        },
        "need_help": "Ho bisogno di aiuto",
        "next": "Avanti",
        "no_network": "Nessuna connessione a Internet",
        "ok": "Ok",
        "perfect": "Perfetto!",
        "selection_on_map": {
            "click_on_map": "Posizionare un punto su una mappa",
            "no_address": "Non riuscite a trovare il vostro indirizzo?"
        },
        "silo_type": {
            "brand_silo": "Marchio Silo",
            "brand_silo_required": "Marchio richiesto",
            "cylindrical_silo_conical_bottom": "Silo cilindrico con fondo conico",
            "cylindrical_tower_bottom": "Torre cilindrica con fondo piatto",
            "diameter_part_right": "Diametro del lato destro (in cm)",
            "diameter_required": "Diametro richiesto",
            "error_get_silo_brands": "Impossibile recuperare i segni di silo.",
            "error_get_silo_models": "Impossibile recuperare i modelli di questa marca di silo.",
            "height_required": "Altezza richiesta",
            "height_total": "Altezza totale (in cm)",
            "height_volume_silo": "Quali sono l'altezza e il volume del vostro silo?",
            "know_brand_model": "Conosco il modello e la marca del mio silo?",
            "length_cone": "Altezza del cono (in cm)",
            "length_cone_required": "Altezza del cono richiesta",
            "length_required": "Lunghezza richiesta",
            "length_right_part": "Lunghezza parte diritta (in cm)",
            "length_width_minimum_cone": "Cono lungo/diam min (in cm)",
            "model_silo": "Modello di silo",
            "model_silo_required": "Modello richiesto",
            "rectangular_silo_pyramidal_bottom": "Silo rettangolare con fondo a piramide",
            "rectangular_tower_flat_bottom": "Torre rettangolare con fondo piatto",
            "silo_type_required": "Tipo di silo richiesto",
            "value_required": "Valore richiesto",
            "volume": "Volume (m3)",
            "volume_required": "Volume richiesto",
            "width_min_cone": "Larghezza minima del cono (in cm)",
            "width_min_cone_required": "Larghezza minima richiesta",
            "width_required": "Larghezza richiesta",
            "width_right_part": "Larghezza del lato destro (in cm)"
        },
        "step_1": {
            "client_find_not_complete": "Avete gi\u00e0 provato a creare un account cliente con questo indirizzo e-mail, ricordatevi di completare la creazione dell'account cliccando sul link ricevuto via e-mail.",
            "client_find_with_mail": "Cliente trovato con questo indirizzo e-mail.",
            "client_not_find_with_mail": "Cliente non trovato con questo indirizzo e-mail.",
            "email_already_taken": "Email gi\u00e0 utilizzata.",
            "email_invitation_inscription_sent": "Una volta completata l'installazione, verr\u00e0 inviata un'e-mail di invito alla registrazione.",
            "email_not_valid": "Formato e-mail non valido",
            "email_required": "Email richiesta",
            "error_during_association": "Errore riscontrato durante l'associazione del sensore",
            "for_a_client": "Per un cliente",
            "professional_account": "Per l'azienda",
            "send_registration_mail": "Inviare un'e-mail di registrazione",
            "title": "Contatto",
            "welcome_to_installation": "Benvenuti all'installazione di un sensore in linea",
            "wish_associate_device_to": "Voglio combinare il sensore"
        },
        "step_2": {
            "camera_error": "Fotocamera non supportata",
            "device_cant_be_install": "Il sensore non pu\u00f2 essere installato.",
            "device_not_found": "Dispositivo non trovato",
            "device_number": "Numero del sensore",
            "device_series_number": "Numero di serie",
            "device_series_number_not_found": "Numero di serie non trovato.",
            "device_series_number_required": "Numero di serie richiesto",
            "error_get_device": "Errore riscontrato durante il recupero del sensore",
            "inform_device_number": "Immettere il numero del sensore",
            "message_associate_professional": "*Il vostro sensore sar\u00e0 legato all'azienda, non ci sar\u00e0 spazio personale (Sens). Se si desidera accedere al proprio sensore tramite uno spazio personale, inserire il proprio indirizzo e-mail nel passaggio precedente.",
            "not_access_device_number": "Non ho accesso al numero del sensore",
            "number_required": "Numero richiesto",
            "title": "Sensore"
        },
        "step_3": {
            "boiler": "Caldaia",
            "chaudiere": "Caldaia",
            "choose_equipment_type": "Scegliere il tipo di attrezzatura:",
            "cuve": "Serbatoio",
            "device_associate_to": "L'attrezzatura \u00e8 ben associata al conto aziendale",
            "equipment_type": "Tipo di attrezzatura",
            "equipment_type_required": "Tipo di attrezzatura richiesta",
            "error_get_asset_types": "Errore riscontrato durante il recupero dei tipi di apparecchiature",
            "ground_water": "Falda acquifera",
            "hive": "Log",
            "no_equipment_type": "Nessun tipo di attrezzatura",
            "ouvrage": "Lavoro",
            "reel": "Mulinello",
            "silo": "Silo",
            "title": "Tipo di attrezzatura"
        },
        "step_4": {
            "appartement": "piatto",
            "boiler": "caldaia",
            "chaudiere": "caldaia",
            "cuve": "serbatoio",
            "equipment": "attrezzature",
            "error_domicile": "Casa non impostata correttamente",
            "error_during_installation": "Errore durante l'installazione",
            "ground_water": "falda acquifera",
            "machining_machine": "centro di lavoro",
            "no_installation_for_this_type": "Installazione non implementata per questo tipo di apparecchiatura",
            "ouvrage": "lavoro",
            "reel": "mulinello",
            "ruche": "Alveare",
            "silo": "silo",
            "title": "Configurazione"
        },
        "tank_type": {
            "coated_cylindrical_format": "Formato cilindrico rivestito",
            "error_get_tank_brands": "Impossibile recuperare i segni del serbatoio",
            "error_get_tank_models": "Impossibile recuperare i modelli di questa marca di carri armati.",
            "height": "Altezza (in cm)",
            "height_required": "Altezza richiesta",
            "height_volume_tank": "Quali sono l'altezza e il volume della vasca?",
            "ibc_format": "Formato IBC",
            "is_mobile": "\u00c8 montato su ruote?",
            "know_brand_model": "Conosco il modello e la marca del mio serbatoio?",
            "length_interior": "Lunghezza interna (in cm)",
            "length_interior_required": "Lunghezza richiesta",
            "rectangular_format": "Formato rettangolare",
            "spherical_format": "Formato sferico",
            "tank_brand": "Marca del serbatoio",
            "tank_brand_required": "Marchio richiesto",
            "tank_model": "Modello di serbatoio",
            "tank_model_required": "Modello richiesto",
            "tank_type_required": "Tipo di serbatoio richiesto",
            "vertical_cylindrical_format": "Formato cilindrico verticale",
            "volume": "Volume (litri)",
            "volume_required": "Volume richiesto",
            "width_interior": "Larghezza interna (in cm)",
            "width_interior_required": "Larghezza richiesta"
        },
        "validate": "Convalidare"
    },
    "nl": {
        "activate": "Activeer",
        "actualize": "Update",
        "add_client_dialog_component": {
            "add_client": "Een klant toevoegen",
            "added_client": "Klant toegevoegd",
            "client_reference": "Klantreferentie",
            "client_space_name": "Naam ruimte betekenis",
            "company_name": "Bedrijfsnaam",
            "siren": "SIREN"
        },
        "asset_domicile": {
            "address_find": "Adres gevonden",
            "bad_marker": "Het geplaatste punt komt niet overeen met een voldoende nauwkeurig adres",
            "complete_address_equipment": "Wat is het volledige adres van uw nieuwe apparatuur",
            "domicile": "Huizen",
            "domicile_name": "Naam van uw huis",
            "domicile_required": "Verblijfplaats vereist",
            "error_browser_localisation": "Uw browser ondersteunt geen geolocatie.",
            "error_geocode": "Fout bij de google geocode",
            "error_get_user_client_domiciles": "Fout bij het herstel van woningen",
            "error_localisation_precision": "Onmogelijk om je correct te lokaliseren.",
            "error_message_domicile": "Adres niet nauwkeurig genoeg",
            "inform_domicile_address": "Vul uw huisadres in",
            "locate_me": "Geolokaliseer mij",
            "no_domicile": "Geen huis.",
            "place_on_map": "Een punt op een kaart plaatsen",
            "refuse_localisation": "Je hebt geolocatie geweigerd.",
            "use_existing_address": "Een bestaand adres gebruiken",
            "use_new_address": "Gebruik een nieuw adres"
        },
        "asset_model_form_wrapper_component": {
            "content": "Inhoud",
            "select_container_type": "Selecteer een containertype"
        },
        "asset_specification": {
            "code_asset": "Activa code (optioneel)",
            "description": "Beschrijving",
            "equipment_information": "Informatie over de uitrusting",
            "equipment_specifications": "Wat zijn de kenmerken van uw apparatuur",
            "error_get_liquids": "Onmogelijk om vloeistoffen te recupereren.",
            "error_get_localisations": "Kan locaties niet ophalen.",
            "honey_type": "Soort honing",
            "honey_type_required": "Type honing nodig",
            "intermediate_screw_pitch": "2 - Gemiddelde schroefafstand",
            "large_screw_pitch": "3 - Grote schroefdraad",
            "liquid_type": "Soort vloeistof",
            "liquid_type_required": "Type vloeistof nodig",
            "localisation": "Locatie",
            "localisation_required": "Vereiste locatie",
            "monitoring_consumption": "Controle van het verbruik",
            "monitoring_filling": "Controle op het vullen",
            "monitoring_type": "Type toezicht",
            "monitoring_type_required": "Aard van het vereiste toezicht",
            "name_required": "Naam verplicht",
            "name_your_equipment": "Uw uitrusting een naam geven om ze gemakkelijker terug te vinden",
            "no_honey_type": "Geen honingtype geassocieerd",
            "no_liquid_type": "Geen bijbehorend vloeistoftype",
            "no_screw_pitch": "Geen schroefdraad",
            "offset": "Offset (cm)",
            "offset_required": "Vereiste compensatie",
            "screw_pitch_number": "Draadnummer",
            "small_screw_pitch": "1 - Kleine schroefdraad"
        },
        "back": "Terug",
        "device_install": "Installatie van de sensor",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Klant",
            "customer_link_to_equipment": "Klant in verband met apparatuur",
            "name_equipment": "Naam apparatuur",
            "ref_equipment": "Uitrusting ref."
        },
        "email": "Email",
        "error": "Interne server fout",
        "error_display_option": "Thema niet hersteld.",
        "fill_all_fields": "Gelieve alle velden in te vullen",
        "generic_form_component": {
            "required_fields": "Verplichte velden"
        },
        "generic_type": {
            "equipment_type_required": "Type apparatuur dat nodig is",
            "field_required": "Verplicht veld",
            "problem_equipment_model": "Kan geen modellen van deze apparatuur ophalen.",
            "taring_bottom": "Lage tarra",
            "taring_top": "Hoge tarra",
            "wrong_dateformat": "Verkeerd datumformaat"
        },
        "global": {
            "activate": "Activeer",
            "add": "Voeg  toe",
            "cancel": "Annuleren",
            "confirm": "Bevestig"
        },
        "installation_result": {
            "add_device_image": "Een foto toevoegen",
            "add_image_succeed": "Foto toegevoegd",
            "bad": "Slecht",
            "camera_not_supported": "Camera niet ondersteund",
            "device_activation": "U hoeft alleen nog maar uw {device_number} sensor handmatig te activeren. Voer handmatig een meting uit om te controleren of de sensor goed ge\u00efnstalleerd is.",
            "email_inscription_sent": "Een registratie e-mail is verzonden",
            "email_inscription_sent_at": "Er is een e-mail gestuurd naar het opgegeven adres",
            "error_add_image": "Fout bij het toevoegen van de afbeelding",
            "error_email_inscription_sent": "Probleem bij het verzenden van de registratie e-mail",
            "finalize_inscription": "Om de registratie te voltooien en de toegangscodes te verkrijgen, klikt u op de e-mail die u is toegezonden.",
            "good": "Goed",
            "height_liquid": "Hoogte van de vloeistof",
            "installation_success": "De installatie ging goed.",
            "medium": "Medium",
            "network_state": "Netwerkstatus",
            "new_installation": "Een nieuwe installatie uitvoeren",
            "no_data": "Geen gegevens",
            "no_device_emission": "Geen emissie van de sensor",
            "no_measure": "Geen metingen gerapporteerd",
            "realized_measure": "Uitgevoerde meting :",
            "reminder_to_check_emails": "Naar het in stap 1 opgegeven e-mailadres werd een activeringsmail gestuurd om toegang te krijgen tot de sensorgegevens.",
            "very_good": "Zeer goed",
            "waiting_measure": "In afwachting van de meting...",
            "weight": "Poids"
        },
        "installation_result_balance": {
            "activate_device": "Om de goede werking van uw weegschaal te controleren en ervoor te zorgen dat uw bijenstal onder de :",
            "activate_device_later": "Ik zal mijn weegschaal later activeren",
            "device_activation": "U hoeft alleen nog maar uw {device_number} schaal handmatig te activeren. Voer handmatig een meting uit om te controleren of de weegschaal goed ge\u00efnstalleerd is.",
            "error_get_first_emission": "Kan geen programma ophalen van de weegschaal",
            "see_device_detail": "Zie de details van het saldo",
            "step_1": "Open uw koffer",
            "step_2": "Plaats de batterij in het batterijvak",
            "step_3": "Wacht op een dubbele pieptoon (indien geen pieptoon, wacht 30 seconden alvorens het opnieuw te proberen)",
            "step_4": "Sluit de doos",
            "step_5": "Klik op activeren om de eerste gegevens van uw weegschaal te verzamelen",
            "success_get_first_emission": "De weegschaal heeft een gegeven teruggestuurd! U kunt de kwaliteit van de gegevens controleren in de details van de schaal."
        },
        "installation_result_generic": {
            "activate_device": "Om de correcte werking van de metingen te controleren zet u de sensor in de \"installatie\"-modus. Ga hiervoor met de magneet rond de sensor om de groene LED te laten verschijnen.",
            "activate_device_later": "Ik zal mijn sensor later activeren",
            "device_activation": "U hoeft alleen nog maar uw {device_number} sensor handmatig te activeren. Voer handmatig een meting uit om te controleren of de sensor correct is ge\u00efnstalleerd.",
            "error_get_first_emission": "Kan geen emissie van de sensor ophalen",
            "see_device_detail": "Zie sensor details",
            "success_get_first_emission": "De sensor heeft gegevens teruggestuurd! U kunt de kwaliteit van de gegevens controleren in de sensordetails."
        },
        "modules": {
            "GNC_16SNSR": "Automatische 16-tank",
            "GNC_8SNSR": "Automaat met 8 vaten",
            "GNC_BMTR": "B-Meters",
            "GNC_BSTRM": "Warmteterugwinningseenheden",
            "GNC_BSTRM_3": "Warmteterugwininstallaties met drie luchtinlaten",
            "GNC_CHMRC": "Opwerkingsfabrieken",
            "GNC_CNT": "Tellers",
            "GNC_DLVL": "Wasmiddel bus",
            "GNC_EMPT": "Blanco generieke module",
            "GNC_GAS": "Benzinetanks",
            "GNC_GPS": "GPS-tracering",
            "GNC_H2": "Waterstof containers",
            "GNC_HIVE": "Bijenkorven",
            "GNC_NETWORK": "Netwerkapparatuur",
            "GNC_NOREA": "Norea vloeistoftanks",
            "GNC_PRESSURE": "Tankdruk",
            "GNC_SCLN": "Verdunningsinstallaties",
            "GNC_SILO": "Silo's",
            "GNC_TANK": "Tanks (CNG)"
        },
        "need_help": "Ik heb hulp nodig.",
        "next": "Volgende",
        "no_network": "Geen internetverbinding",
        "ok": "Ok",
        "perfect": "Perfect!",
        "selection_on_map": {
            "click_on_map": "Een punt op een kaart plaatsen",
            "no_address": "Kunt u uw adres niet vinden?"
        },
        "silo_type": {
            "brand_silo": "Silo merk",
            "brand_silo_required": "Merk vereist",
            "cylindrical_silo_conical_bottom": "Cilindrische silo met conische bodem",
            "cylindrical_tower_bottom": "Cilindrische toren met vlakke bodem",
            "diameter_part_right": "Diameter van de rechterkant (in cm)",
            "diameter_required": "Vereiste diameter",
            "error_get_silo_brands": "Kan silo markeringen niet herstellen.",
            "error_get_silo_models": "Onmogelijk om de modellen van dit merk silo terug te vinden.",
            "height_required": "Vereiste hoogte",
            "height_total": "Totale hoogte (in cm)",
            "height_volume_silo": "Wat is de hoogte en het volume van uw silo?",
            "know_brand_model": "Ik weet het model en merk van mijn silo?",
            "length_cone": "Kegelhoogte (in cm)",
            "length_cone_required": "Vereiste kegelhoogte",
            "length_required": "Vereiste lengte",
            "length_right_part": "Lengte recht stuk (in cm)",
            "length_width_minimum_cone": "Lengte/diameter min. kegel (in cm)",
            "model_silo": "Silomodel",
            "model_silo_required": "Vereist model",
            "rectangular_silo_pyramidal_bottom": "Rechthoekige silo met piramidevormige bodem",
            "rectangular_tower_flat_bottom": "Rechthoekige toren met vlakke bodem",
            "silo_type_required": "Type gewenste silo",
            "value_required": "Vereiste waarde",
            "volume": "Volume (m3)",
            "volume_required": "Vereist volume",
            "width_min_cone": "Minimale kegelbreedte (in cm)",
            "width_min_cone_required": "Minimaal vereiste breedte",
            "width_required": "Vereiste breedte",
            "width_right_part": "Breedte van de rechterkant (in cm)"
        },
        "step_1": {
            "client_find_not_complete": "U hebt al geprobeerd een klantenrekening aan te maken met dit e-mailadres. Vergeet niet de aanmaak van de rekening te voltooien door op de link te klikken die u per e-mail hebt ontvangen.",
            "client_find_with_mail": "Klant gevonden met dit e-mailadres.",
            "client_not_find_with_mail": "Klant niet gevonden met dit e-mailadres.",
            "email_already_taken": "E-mail reeds gebruikt.",
            "email_invitation_inscription_sent": "Een uitnodigingsmail om te registreren wordt verstuurd zodra de installatie is voltooid.",
            "email_not_valid": "Ongeldig e-mail formaat",
            "email_required": "E-mail vereist",
            "error_during_association": "Fout bij het koppelen van de sensor",
            "for_a_client": "Voor een cli\u00ebnt",
            "professional_account": "Voor het bedrijf",
            "send_registration_mail": "Stuur een registratie-e-mail",
            "title": "Neem contact op met",
            "welcome_to_installation": "Welkom bij de installatie van een in-line sensor",
            "wish_associate_device_to": "Ik wil de sensor combineren"
        },
        "step_2": {
            "camera_error": "Camera niet ondersteund",
            "device_cant_be_install": "De sensor kan niet worden ge\u00efnstalleerd.",
            "device_not_found": "Apparaat niet gevonden",
            "device_number": "Sensornummer",
            "device_series_number": "Serienummer",
            "device_series_number_not_found": "Serienummer niet gevonden.",
            "device_series_number_required": "Serienummer vereist",
            "error_get_device": "Fout bij herstel van de sensor",
            "inform_device_number": "Voer het sensornummer in",
            "message_associate_professional": "*Uw sensor wordt gekoppeld aan het bedrijf, er is geen persoonlijke ruimte (Sens). Indien u toegang wenst tot uw sensor via een persoonlijke ruimte, vul dan uw e-mailadres in bij de vorige stap.",
            "not_access_device_number": "Ik heb geen toegang tot het sensornummer",
            "number_required": "Vereist aantal",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Ketel",
            "chaudiere": "Ketel",
            "choose_equipment_type": "Kies het type uitrusting:",
            "cuve": "Tank",
            "device_associate_to": "De apparatuur wordt goed geassocieerd met de bedrijfsrekening",
            "equipment_type": "Type uitrusting",
            "equipment_type_required": "Type apparatuur dat nodig is",
            "error_get_asset_types": "Fout bij het ophalen van apparatuurtypes",
            "ground_water": "Water tabel",
            "hive": "Logboek",
            "no_equipment_type": "Geen type apparatuur",
            "ouvrage": "Werk",
            "reel": "Haspel",
            "silo": "Silo",
            "title": "Type uitrusting"
        },
        "step_4": {
            "appartement": "plat",
            "boiler": "ketel",
            "chaudiere": "ketel",
            "cuve": "tank",
            "equipment": "apparatuur",
            "error_domicile": "Huis niet goed ingesteld",
            "error_during_installation": "Fout tijdens installatie",
            "ground_water": "water tabel",
            "machining_machine": "bewerkingscentrum",
            "no_installation_for_this_type": "Installatie niet uitgevoerd voor dit type apparatuur",
            "ouvrage": "werk",
            "reel": "haspel",
            "ruche": "Bijenkorf",
            "silo": "silo",
            "title": "Configuratie"
        },
        "tank_type": {
            "coated_cylindrical_format": "Gecoat cilindrisch formaat",
            "error_get_tank_brands": "Kan tanksporen niet herstellen",
            "error_get_tank_models": "Onmogelijk om modellen van dit merk tank terug te vinden.",
            "height": "Hoogte (in cm)",
            "height_required": "Vereiste hoogte",
            "height_volume_tank": "Wat is de hoogte en het volume van uw tank?",
            "ibc_format": "IBC formaat",
            "is_mobile": "Is het op het wiel gemonteerd?",
            "know_brand_model": "Ik weet het model en merk van mijn tank?",
            "length_interior": "INSIDE lengte (in cm)",
            "length_interior_required": "Vereiste lengte",
            "rectangular_format": "Rechthoekig formaat",
            "spherical_format": "Sferisch formaat",
            "tank_brand": "Merk tank",
            "tank_brand_required": "Merk vereist",
            "tank_model": "Tank model",
            "tank_model_required": "Vereist model",
            "tank_type_required": "Type tank vereist",
            "vertical_cylindrical_format": "Verticaal cilindrisch formaat",
            "volume": "Volume (liter)",
            "volume_required": "Vereist volume",
            "width_interior": "BINNEN breedte (in cm)",
            "width_interior_required": "Vereiste breedte"
        },
        "validate": "Valideer"
    },
    "pt": {
        "activate": "Activar",
        "actualize": "Actualiza\u00e7\u00e3o",
        "add_client_dialog_component": {
            "add_client": "Adicionar um cliente",
            "added_client": "Cliente adicionado",
            "client_reference": "Refer\u00eancia do cliente",
            "client_space_name": "Significado do espa\u00e7o do nome",
            "company_name": "Nome da empresa",
            "siren": "SIREN"
        },
        "asset_domicile": {
            "address_find": "Endere\u00e7o encontrado",
            "bad_marker": "O ponto colocado n\u00e3o corresponde a um endere\u00e7o suficientemente preciso",
            "complete_address_equipment": "Qual \u00e9 o endere\u00e7o completo do seu novo equipamento",
            "domicile": "Lares",
            "domicile_name": "Nome da sua casa",
            "domicile_required": "Resid\u00eancia requerida",
            "error_browser_localisation": "O seu navegador n\u00e3o suporta a geolocaliza\u00e7\u00e3o.",
            "error_geocode": "Erro encontrado com o geoc\u00f3digo do google",
            "error_get_user_client_domiciles": "Erro encontrado na recupera\u00e7\u00e3o de casas",
            "error_localisation_precision": "Imposs\u00edvel localiz\u00e1-lo correctamente.",
            "error_message_domicile": "Endere\u00e7o n\u00e3o suficientemente preciso",
            "inform_domicile_address": "Introduza o seu endere\u00e7o de casa",
            "locate_me": "Geolocatem-me",
            "no_domicile": "Sem casa",
            "place_on_map": "Colocar um ponto sobre um mapa",
            "refuse_localisation": "Recusou a geolocaliza\u00e7\u00e3o.",
            "use_existing_address": "Utilizar um endere\u00e7o existente",
            "use_new_address": "Utilizar um novo endere\u00e7o"
        },
        "asset_model_form_wrapper_component": {
            "content": "Conte\u00fado",
            "select_container_type": "Selecionar um tipo de contentor"
        },
        "asset_specification": {
            "code_asset": "C\u00f3digo do activo (opcional)",
            "description": "Descri\u00e7\u00e3o",
            "equipment_information": "Informa\u00e7\u00e3o sobre equipamento",
            "equipment_specifications": "Quais s\u00e3o as caracter\u00edsticas do seu equipamento",
            "error_get_liquids": "Imposs\u00edvel recuperar l\u00edquidos.",
            "error_get_localisations": "Incapaz de recuperar locais.",
            "honey_type": "Tipo de mel",
            "honey_type_required": "Tipo de mel necess\u00e1rio",
            "intermediate_screw_pitch": "2 - Passo interm\u00e9dio do parafuso",
            "large_screw_pitch": "3 - Rosca grande",
            "liquid_type": "Tipo de l\u00edquido",
            "liquid_type_required": "Tipo de l\u00edquido necess\u00e1rio",
            "localisation": "Localiza\u00e7\u00e3o",
            "localisation_required": "Localiza\u00e7\u00e3o requerida",
            "monitoring_consumption": "Monitoriza\u00e7\u00e3o do consumo",
            "monitoring_filling": "Monitoriza\u00e7\u00e3o do enchimento",
            "monitoring_type": "Tipo de monitoriza\u00e7\u00e3o",
            "monitoring_type_required": "Tipo de controlo necess\u00e1rio",
            "name_required": "Nome requerido",
            "name_your_equipment": "Nomear o seu equipamento para facilitar a sua procura",
            "no_honey_type": "Nenhum tipo de mel associado",
            "no_liquid_type": "Nenhum tipo de l\u00edquido associado",
            "no_screw_pitch": "Sem rosca",
            "offset": "Deslocamento (cm)",
            "offset_required": "Compensa\u00e7\u00e3o necess\u00e1ria",
            "screw_pitch_number": "N\u00famero da linha",
            "small_screw_pitch": "1 - Rosca pequena"
        },
        "back": "Voltar",
        "device_install": "Instala\u00e7\u00e3o de sensores",
        "edit_asset_of_asset_module_form_component": {
            "customer": "Cliente",
            "customer_link_to_equipment": "Cliente associado ao equipamento",
            "name_equipment": "Nome do equipamento",
            "ref_equipment": "Equipamento ref."
        },
        "email": "Email",
        "error": "Erro interno do servidor",
        "error_display_option": "Tema n\u00e3o recuperado.",
        "fill_all_fields": "Por favor preencha todos os campos",
        "generic_form_component": {
            "required_fields": "Campos obrigat\u00f3rios"
        },
        "generic_type": {
            "equipment_type_required": "Tipo de equipamento necess\u00e1rio",
            "field_required": "Campo obrigat\u00f3rio",
            "problem_equipment_model": "Incapaz de recuperar os modelos deste equipamento.",
            "taring_bottom": "Tara baixa",
            "taring_top": "Tara alta",
            "wrong_dateformat": "Formato de data errado"
        },
        "global": {
            "activate": "Ativar",
            "add": "Adicionar",
            "cancel": "Cancelar",
            "confirm": "Confirmar"
        },
        "installation_result": {
            "add_device_image": "Adicionar uma fotografia",
            "add_image_succeed": "Foto adicionada",
            "bad": "Mau",
            "camera_not_supported": "C\u00e2mara n\u00e3o suportada",
            "device_activation": "Tudo o que resta \u00e9 activar manualmente o seu sensor {device_number}. Executar uma medi\u00e7\u00e3o manualmente para verificar se o sensor est\u00e1 devidamente instalado.",
            "email_inscription_sent": "Foi enviado um e-mail de registo",
            "email_inscription_sent_at": "Foi enviado um e-mail para o endere\u00e7o indicado",
            "error_add_image": "Erro ao adicionar a imagem",
            "error_email_inscription_sent": "Problema encontrado ao enviar o e-mail de registo",
            "finalize_inscription": "Para finalizar o registo e obter os c\u00f3digos de acesso, clique no e-mail que foi enviado.",
            "good": "Bom",
            "height_liquid": "Altura do l\u00edquido",
            "installation_success": "A instala\u00e7\u00e3o correu bem.",
            "medium": "M\u00e9dio",
            "network_state": "Estado da rede",
            "new_installation": "Realiza\u00e7\u00e3o de uma nova instala\u00e7\u00e3o",
            "no_data": "Sem dados",
            "no_device_emission": "Nenhuma emiss\u00e3o do sensor",
            "no_measure": "Nenhuma medida comunicada",
            "realized_measure": "Medi\u00e7\u00e3o realizada :",
            "reminder_to_check_emails": "Foi enviado um e-mail de activa\u00e7\u00e3o para o endere\u00e7o de e-mail fornecido no passo 1 para aceder aos dados do sensor.",
            "very_good": "Muito bom",
            "waiting_measure": "\u00c0 espera de medi\u00e7\u00e3o...",
            "weight": "Peso"
        },
        "installation_result_balance": {
            "activate_device": "A fim de verificar se a sua balan\u00e7a est\u00e1 a funcionar correctamente e de assegurar que o seu api\u00e1rio est\u00e1 coberto pelo :",
            "activate_device_later": "Activarei a minha balan\u00e7a mais tarde",
            "device_activation": "Tudo o que resta \u00e9 activar manualmente a sua escala {device_number}. Executar uma medi\u00e7\u00e3o manualmente para verificar se a balan\u00e7a est\u00e1 devidamente instalada.",
            "error_get_first_emission": "Incapaz de recuperar um programa da escala",
            "see_device_detail": "Ver os detalhes do balan\u00e7o",
            "step_1": "Abra o seu caso",
            "step_2": "Inserir a bateria no compartimento da bateria",
            "step_3": "Esperar por um bip duplo (se n\u00e3o houver bip, por favor aguarde 30 segundos antes de tentar novamente)",
            "step_4": "Fechar a caixa",
            "step_5": "Clique em activar para recolher os primeiros dados da sua balan\u00e7a",
            "success_get_first_emission": "A balan\u00e7a devolveu um dado! Pode verificar a qualidade dos dados nos detalhes da escala."
        },
        "installation_result_generic": {
            "activate_device": "Para verificar o correcto funcionamento das leituras, por favor colocar o sensor em modo \"instala\u00e7\u00e3o\". Para o fazer, passe o \u00edman \u00e0 volta do sensor para fazer aparecer o LED verde.",
            "activate_device_later": "Activarei o meu sensor mais tarde",
            "device_activation": "Tudo o que resta \u00e9 activar manualmente o seu sensor {device_number}. Executar uma medi\u00e7\u00e3o manualmente para verificar se o sensor est\u00e1 instalado correctamente.",
            "error_get_first_emission": "Incapaz de recuperar uma emiss\u00e3o do sensor",
            "see_device_detail": "Ver detalhes do sensor",
            "success_get_first_emission": "O sensor enviou de volta um dado! Pode verificar a qualidade dos dados nos detalhes do sensor."
        },
        "modules": {
            "GNC_16SNSR": "Autom\u00e1tico de 16 tanques",
            "GNC_8SNSR": "Autom\u00e1tico de 8 recipientes",
            "GNC_BMTR": "Medidores B",
            "GNC_BSTRM": "Unidades de recupera\u00e7\u00e3o de calor",
            "GNC_BSTRM_3": "Recuperadores de calor de tr\u00eas entradas",
            "GNC_CHMRC": "Instala\u00e7\u00f5es de reprocessamento",
            "GNC_CNT": "Contadores",
            "GNC_DLVL": "Recipiente de detergente",
            "GNC_EMPT": "M\u00f3dulo gen\u00e9rico em branco",
            "GNC_GAS": "Dep\u00f3sitos de g\u00e1s",
            "GNC_GPS": "Localiza\u00e7\u00e3o por GPS",
            "GNC_H2": "Contentores de hidrog\u00e9nio",
            "GNC_HIVE": "Colmeias",
            "GNC_NETWORK": "Equipamento de rede",
            "GNC_NOREA": "Dep\u00f3sitos de l\u00edquidos Norea",
            "GNC_PRESSURE": "Press\u00e3o do dep\u00f3sito",
            "GNC_SCLN": "Instala\u00e7\u00f5es de dilui\u00e7\u00e3o",
            "GNC_SILO": "Silos",
            "GNC_TANK": "Dep\u00f3sitos (GNC)"
        },
        "need_help": "Preciso de ajuda",
        "next": "Pr\u00f3ximo",
        "no_network": "Sem liga\u00e7\u00e3o \u00e0 Internet",
        "ok": "Ok",
        "perfect": "Perfeito!",
        "selection_on_map": {
            "click_on_map": "Colocar um ponto sobre um mapa",
            "no_address": "N\u00e3o consegue encontrar a sua morada?"
        },
        "silo_type": {
            "brand_silo": "Marca Silo",
            "brand_silo_required": "Marca requerida",
            "cylindrical_silo_conical_bottom": "Silo cil\u00edndrico com fundo c\u00f3nico",
            "cylindrical_tower_bottom": "Torre cil\u00edndrica com fundo plano",
            "diameter_part_right": "Di\u00e2metro do lado direito (em cm)",
            "diameter_required": "Di\u00e2metro necess\u00e1rio",
            "error_get_silo_brands": "Incapaz de recuperar as marcas de silo.",
            "error_get_silo_models": "Imposs\u00edvel recuperar os modelos desta marca de silo.",
            "height_required": "Altura necess\u00e1ria",
            "height_total": "Altura total (em cm)",
            "height_volume_silo": "Qual \u00e9 a altura e o volume do seu silo?",
            "know_brand_model": "Conhe\u00e7o o modelo e a marca do meu silo?",
            "length_cone": "Altura do cone (em cm)",
            "length_cone_required": "Altura do cone necess\u00e1ria",
            "length_required": "Comprimento requerido",
            "length_right_part": "Comprimento parte direita (em cm)",
            "length_width_minimum_cone": "Cone de minas Long/Diam (em cm)",
            "model_silo": "Modelo de silo",
            "model_silo_required": "Modelo necess\u00e1rio",
            "rectangular_silo_pyramidal_bottom": "Silo rectangular com fundo em pir\u00e2mide",
            "rectangular_tower_flat_bottom": "Torre rectangular com fundo plano",
            "silo_type_required": "Tipo de silo necess\u00e1rio",
            "value_required": "Valor necess\u00e1rio",
            "volume": "Volume (m3)",
            "volume_required": "Volume necess\u00e1rio",
            "width_min_cone": "Largura m\u00ednima do cone (em cm)",
            "width_min_cone_required": "Largura m\u00ednima necess\u00e1ria",
            "width_required": "Largura necess\u00e1ria",
            "width_right_part": "Largura do lado direito (em cm)"
        },
        "step_1": {
            "client_find_not_complete": "J\u00e1 tentou criar uma conta de cliente com este endere\u00e7o de e-mail, lembre-se de finalizar a cria\u00e7\u00e3o da conta clicando no link recebido por e-mail.",
            "client_find_with_mail": "Cliente encontrado com este endere\u00e7o electr\u00f3nico.",
            "client_not_find_with_mail": "Cliente n\u00e3o encontrado com este endere\u00e7o de e-mail.",
            "email_already_taken": "E-mail j\u00e1 utilizado.",
            "email_invitation_inscription_sent": "Um e-mail de convite para registo ser\u00e1 enviado assim que a instala\u00e7\u00e3o estiver conclu\u00edda",
            "email_not_valid": "Formato de e-mail inv\u00e1lido",
            "email_required": "Email requerido",
            "error_during_association": "Erro encontrado ao associar o sensor",
            "for_a_client": "Para um cliente",
            "professional_account": "Para a empresa",
            "send_registration_mail": "Enviar um e-mail de registo",
            "title": "Contacto",
            "welcome_to_installation": "Bem-vindo \u00e0 instala\u00e7\u00e3o de um sensor em linha",
            "wish_associate_device_to": "Quero combinar o sensor"
        },
        "step_2": {
            "camera_error": "C\u00e2mara n\u00e3o suportada",
            "device_cant_be_install": "O sensor n\u00e3o pode ser instalado.",
            "device_not_found": "Dispositivo n\u00e3o encontrado",
            "device_number": "N\u00famero do sensor",
            "device_series_number": "N\u00famero de s\u00e9rie",
            "device_series_number_not_found": "N\u00famero de s\u00e9rie n\u00e3o encontrado.",
            "device_series_number_required": "N\u00famero de s\u00e9rie necess\u00e1rio",
            "error_get_device": "Erro encontrado durante a recupera\u00e7\u00e3o do sensor",
            "inform_device_number": "Introduza o n\u00famero do sensor",
            "message_associate_professional": "*O seu sensor estar\u00e1 ligado \u00e0 empresa, n\u00e3o haver\u00e1 espa\u00e7o pessoal (Sens). Se desejar aceder ao seu sensor atrav\u00e9s de um espa\u00e7o pessoal, introduza por favor o seu endere\u00e7o de correio electr\u00f3nico no passo anterior.",
            "not_access_device_number": "N\u00e3o tenho acesso ao n\u00famero do sensor",
            "number_required": "N\u00famero requerido",
            "title": "Sensor"
        },
        "step_3": {
            "boiler": "Caldeira",
            "chaudiere": "Caldeira",
            "choose_equipment_type": "Escolher o tipo de equipamento:",
            "cuve": "Tanque",
            "device_associate_to": "O equipamento est\u00e1 bem associado \u00e0 conta comercial",
            "equipment_type": "Tipo de equipamento",
            "equipment_type_required": "Tipo de equipamento necess\u00e1rio",
            "error_get_asset_types": "Erro encontrado durante a recupera\u00e7\u00e3o de tipos de equipamento",
            "ground_water": "Len\u00e7\u00f3is fre\u00e1ticos",
            "hive": "Registo",
            "no_equipment_type": "Nenhum tipo de equipamento",
            "ouvrage": "Trabalho",
            "reel": "Enrolador",
            "silo": "Silo",
            "title": "Tipo de equipamento"
        },
        "step_4": {
            "appartement": "apartamento",
            "boiler": "caldeira",
            "chaudiere": "caldeira",
            "cuve": "tanque",
            "equipment": "equipamento",
            "error_domicile": "Casa n\u00e3o configurada correctamente",
            "error_during_installation": "Erro durante a instala\u00e7\u00e3o",
            "ground_water": "len\u00e7\u00f3is fre\u00e1ticos",
            "machining_machine": "centro de maquinagem",
            "no_installation_for_this_type": "Instala\u00e7\u00e3o n\u00e3o implementada para este tipo de equipamento",
            "ouvrage": "trabalho",
            "reel": "enrolador",
            "ruche": "Colmeia",
            "silo": "silo",
            "title": "Configura\u00e7\u00e3o"
        },
        "tank_type": {
            "coated_cylindrical_format": "Formato cil\u00edndrico revestido",
            "error_get_tank_brands": "Incapacidade de recuperar marcas de tanques",
            "error_get_tank_models": "Imposs\u00edvel recuperar modelos desta marca de tanque.",
            "height": "Altura (em cm)",
            "height_required": "Altura necess\u00e1ria",
            "height_volume_tank": "Qual \u00e9 a altura e o volume do seu tanque?",
            "ibc_format": "Formato IBC",
            "is_mobile": "\u00c9 montado numa roda?",
            "know_brand_model": "Conhe\u00e7o o modelo e a marca do meu tanque?",
            "length_interior": "Comprimento LADO (em cm)",
            "length_interior_required": "Comprimento requerido",
            "rectangular_format": "Formato rectangular",
            "spherical_format": "Formato esf\u00e9rico",
            "tank_brand": "Marca do tanque",
            "tank_brand_required": "Marca requerida",
            "tank_model": "Modelo de tanque",
            "tank_model_required": "Modelo necess\u00e1rio",
            "tank_type_required": "Tipo de tanque necess\u00e1rio",
            "vertical_cylindrical_format": "Formato cil\u00edndrico vertical",
            "volume": "Volume (litros)",
            "volume_required": "Volume necess\u00e1rio",
            "width_interior": "Largura LADO INTERIOR (em cm)",
            "width_interior_required": "Largura necess\u00e1ria"
        },
        "validate": "Validar"
    }
};