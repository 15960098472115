<template>
    <div id="generic-form-component">
        <template v-for="(key, index) in form_input_keys">
            <div :id="key" :key="index">
                <template v-if="getFormInputData(key, 'type') === 'int' || getFormInputData(key, 'type') === 'float'">
                    <v-text-field
                        v-model="form_data[key]"
                        :label="getFormInputData(key, 'label')"
                        :rules="
                            getFormInputData(key, 'required') ? [(v) => !!v || v === 0 || $t('generic_form_component.required_fields')] : []
                        "
                        filled
                        type="number"
                        @change="handleBasicInputChange(key)"
                    />
                </template>

                <template v-else-if="getFormInputData(key, 'type') === 'string'">
                    <v-text-field
                        v-model="form_data[key]"
                        :label="getFormInputData(key, 'label')"
                        :rules="getFormInputData(key, 'required') ? [(v) => !!v || $t('generic_form_component.required_fields')] : []"
                        filled
                        type="text"
                        @change="handleBasicInputChange(key)"
                    />
                </template>

                <template v-else-if="getFormInputData(key, 'type') === 'enum'">
                    <v-select
                        v-model="form_data[key]"
                        :items="parsed_enum[key]"
                        :label="getFormInputData(key, 'label')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="
                            getFormInputData(key, 'required') ? [(v) => !!v || v === 0 || $t('generic_form_component.required_fields')] : []
                        "
                        filled
                        item-text="label"
                        item-value="value"
                        v-on:change="(value) => handleEnumChange(key, value)"
                    />
                </template>

                <template v-else-if="getFormInputData(key, 'type') === 'child_enum'">
                    <v-select
                        v-model="form_data[key]"
                        :items="
                            parseChildEnum(
                                parsed_enum[key].find((item) => item.value === parseInt(form_data[getFormInputData(key, 'parent_enum')])),
                            )
                        "
                        :label="getFormInputData(key, 'label')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="
                            getFormInputData(key, 'required') ? [(v) => !!v || v === 0 || $t('generic_form_component.required_fields')] : []
                        "
                        filled
                        item-text="label"
                        item-value="value"
                        v-on:change="(value) => handleEnumChange(key, value)"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'GenericFormComponent',
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        form_inputs: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form_data: {},
            form_input_keys: [],
            parsed_enum: {},
        }
    },
    created() {
        this.generateGenericForm(this.configuration)
    },
    watch: {
        form_inputs() {
            this.generateGenericForm(this.configuration)
        },
    },
    methods: {
        generateGenericForm(values, resendConfig = true) {
            let tempFormData = {}
            this.form_input_keys = []
            this.parsed_enum = {}

            if (this.form_inputs !== undefined) {
                this.form_input_keys = Object.keys(this.form_inputs)

                this.form_input_keys.forEach((key) => {
                    if (this.getFormInputData(key, 'type') === 'int' || this.getFormInputData(key, 'type') === 'float') {
                        tempFormData[key] = values ? values[key] / (this.form_inputs[key].multiplier ? this.form_inputs[key].multiplier : 1) : 0
                    } else {
                        tempFormData[key] = values ? values[key] : ''
                    }

                    if (this.getFormInputData(key, 'type') === 'enum' || this.getFormInputData(key, 'type') === 'child_enum') {
                        Object.keys(this.getFormInputData(key, 'enum')).forEach((key_enum, index) => {
                            if (this.parsed_enum[key]) {
                                this.parsed_enum[key].push({
                                    value: parseInt(key_enum),
                                    label: this.getFormInputData(key, 'enum')[key_enum],
                                })
                            } else {
                                this.parsed_enum[key] = [
                                    {
                                        value: parseInt(key_enum),
                                        label: this.getFormInputData(key, 'enum')[key_enum],
                                    },
                                ]
                            }
                        })
                    }
                })
                this.form_data = tempFormData

                // Update on create form to handle default values
                if (resendConfig) {
                    this.handleEditConfiguration('')
                }
            }
        },

        handleNewConfiguration(values) {
            this.generateGenericForm(values, false)
        },

        handleBasicInputChange(key) {
            this.handleEditConfiguration(key)
        },

        handleEditConfiguration(lastInputChange) {
            let form = { ...this.form_data }
            Object.keys(this.form_inputs).forEach((key) => {
                if (this.form_inputs[key].type === 'int') {
                    form[key] = parseInt(form[key]) * (this.form_inputs[key].multiplier ? this.form_inputs[key].multiplier : 1)
                } else if (this.form_inputs[key].type === 'float') {
                    form[key] = parseFloat(form[key]) * (this.form_inputs[key].multiplier ? this.form_inputs[key].multiplier : 1)
                }
            })

            this.$emit('update:configuration', { form: form, lastInputChange: lastInputChange })
        },

        getFormInputData(key, data) {
            return this.form_inputs[key][data]
        },

        handleEnumChange(key, value) {
            this.form_data[key] = value

            this.getFormInputData(key, 'child_enum').forEach((child_key) => {
                this.form_data[child_key] = null
            })

            this.handleEditConfiguration(key)
        },

        parseChildEnum(childEnum) {
            let result = []

            if (childEnum) {
                Object.keys(childEnum.label).forEach((value) => {
                    result.push({ value: parseInt(value), label: childEnum.label[value] })
                })
            }

            return result
        },
    },
}
</script>
